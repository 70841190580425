import React, { useState } from 'react';
import { observer } from "mobx-react-lite";
import Cropper from 'react-easy-crop';
import { Slider } from 'rsuite';

const MaxFileLength = 10000000;

const PhotoEditField = observer(({ bioStore, interfaceStateStore, serverSynchronization, label, slot }) => {
	function handleUpload(e) {
		const input = e.target;
		if(input.files && input.files.length > 0) {
			const file = input.files[0];
			if(file.size && file.size > MaxFileLength) {
				alert("Oops! Looks like your photo exceeded our size limit, please try again!")
			} else {
				bioStore.updatePhoto(slot, { cropX: 0, cropY: 0, cropWidth: 0, cropHeight: 0 });
				const extension = "jpg";
				const photo = bioStore.photos.get(slot);
				serverSynchronization.uploadPhoto(file, slot, extension, photo.revisionNumber, false);
			}
		} else {
			alert("Unable to open photo, please try again!");
		}
	}
	
	function handleDelete() {
		bioStore.updatePhoto(slot, { customization: "noPhoto" });	
	}

	function cropComplete(croppedArea, croppedAreaPixels) {
		bioStore.updatePhoto(slot, { cropX: croppedAreaPixels.x, cropY: croppedAreaPixels.y, cropWidth: croppedAreaPixels.width, cropHeight: croppedAreaPixels.height });
	}
	
	// TODO: fix this horrible hackey patch: something goes wrong loading very large images (in Safari on iOS, and possibly others) that leaves the image displaying only partially loaded. It doesn't seem to "recover" until its size/layout is re-set after a delay. 
	function reformatImage() {
		document.querySelectorAll(".reactEasyCrop_Image").forEach(img => {
			if(img && img.style) {
				img.style.setProperty("max-width", "unset");
				img.style.setProperty("max-height", "unset");

				setTimeout(function() {
					img.style.setProperty("max-width", "100%");
					img.style.setProperty("max-height", "100%");	
				}, 500);
			}
		})
	}

	// Non-persisted state necessary for the cropper's intermediate work before providing us with final pixel values on onCropComplete
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);

	let cropperElement = null;
	let zoomElement = null;
	let deleteElement = null;
	let photo = bioStore.photos.get(slot);
	if(photo.customization === "providedCustom") {
		const photoURL = serverSynchronization.getMediaURL(slot, "-uncropped", photo.uncroppedExtension, photo.revisionNumber);
		let initialCrop = null;
		if(photo.cropWidth > 0 && photo.cropHeight > 0) { // Zeros for the size break the cropper, so only provide the initial crop if we have the size
			initialCrop = { x: photo.cropX, y: photo.cropY, width: photo.cropWidth, height: photo.cropHeight };
		}
		cropperElement = (<Cropper image={photoURL}
			crop={crop}
			zoom={zoom}
			aspect={1}
			zoomWithScroll={false}
			initialCroppedAreaPixels={initialCrop}
			onCropChange={setCrop}
			onCropComplete={cropComplete}
			onZoomChange={setZoom}
			onMediaLoaded={reformatImage}
			classes={{ containerClassName: "photo-edit-cropper"}}
		/>);
		zoomElement = (
			<div className="photo-crop-zoom">
				<label>Zoom: </label>
				<Slider value={zoom} min={1} max={3} step={0.1} onChange={setZoom}></Slider>
			</div>
		);
		deleteElement = <button onClick={handleDelete} className="delete-button">Delete Photo</button>;
	}
	
	return (
		<>
			<div className="edit-panel-field">
				<label>{label}</label>
				<input type="file" onChange={handleUpload} accept=".png, .jpg, .jpeg" />
				{ cropperElement && <div className="photo-edit-cropper-container">{cropperElement}</div> }
				{ zoomElement  }
				{ deleteElement }
			</div>
		</>
	);
});
 
export default PhotoEditField;
 