import React from 'react';
import { observer } from "mobx-react-lite";
import Joyride from 'react-joyride';

const IntroTour = observer(({interfaceStateStore}) => {
	let steps = [
		{
			target: '#root',
			content: <><strong>WELCOME!</strong> First things first: a quick tour around your new link!</>,
			disableBeacon: true,
			placement: 'center'
		},
		{
			target: '.intro-text .edit-button',
			content: <><strong>CUSTOMIZE:</strong> Click on all the PINK icons to easily customize your link.</>,
			disableBeacon: true,
			placement: 'bottom'
		},
		{
			target: '#root',
			content: <><strong>ALL PRO LINKS</strong> have 5 sections to give you a professional layout.</>,
			disableBeacon: true,
			placement: 'center'
		},
		{
			target: '.intro-text .intro-text-heading',
			content:
				interfaceStateStore.hasPaidPlan ? 
					<><strong>INTRODUCTION:</strong> Capture your visitors attention with a powerful introduction and HOT BUTTON to add your most important link that will get the most clicks.</>
					: <><strong>INTRODUCTION:</strong> Capture your visitors attention with a powerful introduction.</>,
			disableBeacon: true,
			placement: 'bottom'
		},
		{
			target: '.links-link-list-row',
			content: <><strong>BUTTONS:</strong> Direct your visitors to a link or ask a question to build your email list.</>,
			disableBeacon: true,
			placement: 'top'
		},
		{
			target: '.carousel-cards',
			content: <><strong>CAROUSEL:</strong> Highlight any current promotions, events, or passions you have.</>,
			disableBeacon: true,
			placement: 'top',
			onlyPro: true
		},
		{
			target: '.testimonial-row',
			content: <><strong>SOCIAL PROOF:</strong> Share any third-party validation, a quote or before & after pictures.</>,
			disableBeacon: true,
			placement: 'top',
			onlyPro: true
		},
		{
			target: '.contact-row',
			content: <><strong>BIZ CARD:</strong> Add your contact information and social tags making it easy for anyone to connect with you.</>,
			disableBeacon: true,
			placement: 'top',
			onlyPro: true
		},
		{
			target: '.switch-theme-button',
			content: <><strong>THEMES:</strong> Try out different themes to decide the right one for your brand.</>,
			disableBeacon: true,
			placement: 'top',
			onlyPro: true
		},
		{
			target: '.share-container .share-button',
			content: <><strong>SHARE:</strong> Once your link is published use this Share menu to easily share it anywhere!</>,
			placement: 'bottom',
			disableBeacon: true
		},
		{
			target: '.done-with-bio-button',
			content: 'Click Done once you\'ve finished customizing your link. Remember: you can always come back to edit once you\'ve published! Nothing is permanent!',
			placement: 'top',
			disableBeacon: true
		}
	];
	
	if(!interfaceStateStore.hasPaidPlan) {
		steps = steps.filter(step => !step.onlyPro);
	}
	
	
	function handleStepChange(data) {
		if(data.action === "reset") {
			window.scrollTo({ top: 0, behavior: 'smooth' });
			interfaceStateStore.setIntroState("none");
		}
	}
	
	return (
		<Joyride
			steps={steps}
			showSkipButton={true}
			showProgress={true}
			hideCloseButton={true}
			continuous={true}
			disableOverlay={false}
			callback={handleStepChange}
			scrollOffset={58} // Account for the top nav bar
			locale={{
				back: 'Back',
				close: 'Close',
				last: 'Get Started',
				next: 'Next',
				open: 'Open the dialog',
				skip: 'Skip'
			}}
			styles={{
				options: {
					zIndex: 10000,
					primaryColor: "#3A6D7A",
					overlayColor: 'rgba(0, 0, 0, 0.5)',
				},
			}}
		/>
	);
});
 
export default IntroTour;
