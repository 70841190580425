import React from 'react';
import { observer } from "mobx-react-lite";

const EditButton = observer(({ addLabel, editLabel, isAdd, onClick }) => {
	function editClick(e) {
		if(onClick) onClick(isAdd);
		e.preventDefault();
		e.stopPropagation();
	}
	let classes = "edit-button";
	if(isAdd) classes += " edit-button-for-adding";
	const label = isAdd ? addLabel : editLabel;
	const iconClass = isAdd ? "fas fa-plus-square" : "fas fa-pen-square";
	return (
		<button className={classes} onClick={editClick}>
			<i className={iconClass}></i>
			{ label && <span>{label}</span> }
		</button>
	);
});
 
export default EditButton;
 
