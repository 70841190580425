import React from 'react';
import { observer } from "mobx-react-lite";
import EditPanel from './EditPanel';
import { Input } from 'rsuite';
import PhotoEditField from './PhotoEditField';
import cropPhoto from './PhotoCropping';

const TestimonialEditPanel = observer(({ bioStore, interfaceStateStore, serverSynchronization }) => {
	function headingChange(value) {
		bioStore.setTestimonialHeading(value);
	}
	function bodyChange(value) {
		bioStore.setTestimonialBody(value);
	}
	function attributionChange(value) {
		bioStore.setTestimonialAttribution(value);
	}
	function uploadPhoto() {
		cropPhoto(bioStore, interfaceStateStore, serverSynchronization, 'testimonial-photo').then(() => interfaceStateStore.setCurrentlyEditing(null, null, null));
	}
	function deleteContent() {
		bioStore.setTestimonialHeading("");
		bioStore.setTestimonialBody("");
		bioStore.setTestimonialAttribution("");
		bioStore.updatePhoto("testimonial-photo", { customization: "noPhoto", cropX: 0, cropY: 0, cropWidth: 0, cropHeight: 0 });
		interfaceStateStore.setCurrentlyEditing(null, null, null);
	}
	
	return (
		<EditPanel
			bioStore={bioStore}
			interfaceStateStore={interfaceStateStore}
			itemType="testimonial"
			title="Social Proof"
			helpText={{
				'bio': 'Get creative and leave a lasting impression. Use this section to share a testimonial, a powerful quote, before & after results, customer reviews or anything you feel that adds validation for what you are sharing.',
				'quickclick': 'Get creative and leave a lasting impression. Use this section to share a testimonial, a powerful quote, before & after results, customer reviews or anything you feel that adds validation.',
				'quiz': 'Get creative and leave a lasting impression. Use this section to share a testimonial, a powerful quote, before & after results, customer reviews or anything you feel that adds validation.',
				'informational': 'Get creative and leave a lasting impression. Use this section to share a testimonial, a powerful quote, before & after results, customer reviews or anything you feel that adds validation.'
			}}
			proTipText={{
				'bio': 'Sharing a customer\'s experience ignites a spark of curiosity in a potential customer!',
				'quickclick': null,
				'quiz': null,
				'informational': null
			}}
			onDone={uploadPhoto}
			showDelete={true}
			onDelete={deleteContent}
			deleteLabel="Clear this section"
			showTipsButton={true}
		>
			<PhotoEditField bioStore={bioStore} interfaceStateStore={interfaceStateStore} serverSynchronization={serverSynchronization} label="Photo:" slot="testimonial-photo"></PhotoEditField>
			
			<div className="edit-panel-field">
				<label>Heading:</label>
				<Input value={bioStore.testimonialHeading || ""} onChange={headingChange} />
			</div>
			<div className="edit-panel-field">
				<label>Body:</label>
				<Input value={bioStore.testimonialBody || ""} componentClass="textarea" rows={3} onChange={bodyChange} />
			</div>
			<div className="edit-panel-field">
				<label>Name:</label>
				<Input value={bioStore.testimonialAttribution || ""} onChange={attributionChange} />
			</div>
		</EditPanel>
	);
});
 
export default TestimonialEditPanel;
