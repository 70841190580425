import React from 'react';
import { observer } from "mobx-react-lite";
import EditButton from './EditButton';
import {contentForDisplayMode} from './Stores';
import {cleanUserProvidedLink} from './URLCleanupUtil';

const ContactSocialMediaList = observer(({bioStore, interfaceStateStore, theme, themeProperties, displayMode, serverSynchronization}) => {
	function stripAmpersands(url) {
		if(!url) return url;
		return url.replace("@", "");	
	}
	function liForURL(urlOrUsername, icon, urlPrefix, logActionType) {
		if(urlOrUsername) {
			return <li>
				<a href={urlPrefix + urlOrUsername} onClick={() => serverSynchronization.logAction(logActionType) } target="_blank" rel="noreferrer">
					<i className={`fab ${icon}`}></i>
					</a>
				</li>
		} else {
			return null;
		}
	}

	const facebookURL = contentForDisplayMode(displayMode, cleanUserProvidedLink(bioStore.facebookURL), "https://www.example.com");
	const pinterestURL = contentForDisplayMode(displayMode, cleanUserProvidedLink(bioStore.pinterestURL), "https://www.example.com");
	const twitterHandle = contentForDisplayMode(displayMode, bioStore.twitterHandle, "msnentrepreneur");
	const instagramUsername = contentForDisplayMode(displayMode, bioStore.instagramUsername, "msnentrepreneur");
	const youtubeURL = contentForDisplayMode(displayMode, cleanUserProvidedLink(bioStore.youtubeURL), "https://www.example.com");
	const linkedinURL = contentForDisplayMode(displayMode, cleanUserProvidedLink(bioStore.linkedinURL), "https://www.example.com");
	const tikTokUsername = contentForDisplayMode(displayMode, bioStore.tikTokUsername, "");
	const amazonURL = contentForDisplayMode(displayMode, cleanUserProvidedLink(bioStore.amazonURL), "https://www.amazon.com");
	
	const hasContent = (facebookURL || pinterestURL || twitterHandle || instagramUsername || youtubeURL || linkedinURL || tikTokUsername || amazonURL);
	let editButton = null;
	if(displayMode === "editing") {
		editButton = (
			<EditButton
				addLabel="Add Social Accounts"
				isAdd={!hasContent}
				onClick={() => interfaceStateStore.setCurrentlyEditing("contact-social-media-list", null, null)}
			>
			</EditButton>
		);
	}

	if(hasContent) {
		return (
			<ul className="contact-social-media-list">
				{ liForURL(facebookURL, "fa-facebook-f", "", "click-social-facebook") }
				{ liForURL(pinterestURL, "fa-pinterest-p", "", "click-social-pinterest") }
				{ liForURL(stripAmpersands(twitterHandle), "fa-twitter", "https://twitter.com/", "click-social-twitter") }
				{ liForURL(stripAmpersands(instagramUsername), "fa-instagram", "https://www.instagram.com/", "click-social-instagram") }
				{ liForURL(youtubeURL, "fa-youtube", "", "click-social-youtube") }
				{ liForURL(linkedinURL, "fa-linkedin-in", "", "click-social-linkedin") }
				{ liForURL(stripAmpersands(tikTokUsername), "fa-tiktok", "https://www.tiktok.com/@", "click-social-tiktok") }
				{ liForURL(amazonURL, "fa-amazon", "", "click-social-amazon") }
				{ editButton && <li>{editButton}</li> }
			</ul>
		);
	} else {
		return editButton;
	}
});
 
export default ContactSocialMediaList;