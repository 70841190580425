import getCroppedImg from './CropUtil';

const MaxDimension = 1024; // Don't exceed 1024 pixels on either dimension

export default async function cropPhoto(bioStore, interfaceStateStore, serverSynchronization, slot) {
	const photo = bioStore.photos.get(slot);
	if(photo.revision === 0 || photo.customization !== "providedCustom") return;
	// We use a cache buster parameter here because of a tricky problem: it seems that Safari (and maybe other browsers) may cache the results of our normal, non-cropping requests for the image because S3 doesn't send Vary: Origin headers if no Origin is provided. So for cropping, we need to guarantee a "fresh" version of the image that will be sent with the CORS information necessary to allow us to load the image and read it from the canvas later	
	const sourceURL = serverSynchronization.getMediaURL(slot, "-uncropped", photo.uncroppedExtension, photo.revisionNumber, true);
	const crop = { x: photo.cropX, y: photo.cropY, width: photo.cropWidth, height: photo.cropHeight };
	interfaceStateStore.setServerSyncInProgress("photo");
	const croppedImage = await getCroppedImg(sourceURL, crop, MaxDimension);
	serverSynchronization.uploadPhoto(croppedImage, slot, "jpg", photo.revisionNumber, true);
}