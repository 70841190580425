import { observer } from 'mobx-react-lite';
import React from 'react';
import { Input, Checkbox } from 'rsuite';
import HelpButton from './HelpButton';

const LinkURLQuestionEditFields = observer(({ linkURL, hasPaidPlan }) => {
	function questionTextChange(text) {
		linkURL.setQuestionText(text);
	}
	function requireEmailChange(value, checked) {
		linkURL.setRequireEmail(checked);
	}
	function createAnswerField(answer, index) {
		function answerTextChange(newAnswer) {
			linkURL.updateAnswer(index, newAnswer);
		}
		function deleteAnswer() {
			linkURL.deleteAnswer(index);
		}
		
		const placeholder = "Option " + (index + 1);
		
		return (
			<div className="edit-panel-field">
				<div className="input-with-side-button">
					<Input value={answer} onChange={answerTextChange} autoFocus placeholder={placeholder} />
					<button className="delete-button" onClick={deleteAnswer}>x</button>
				</div>
			</div>
		);
	}
	function addAnswer() {
		linkURL.updateAnswer(linkURL.answers.length, "");	
	}

	if(hasPaidPlan) {
		return (
			<>
				<div className="edit-panel-field" style={{ marginTop: "1.3em" }}>
					<div className="input-with-side-button">
						<Input value={linkURL.questionText} onChange={questionTextChange} placeholder="Ask a Question"></Input>
						<HelpButton>
							<p>Asking a Question allows you to get interactive responses and engagement from your audience to generate leads. The responses will be sent directly to your inbox and accessible in your analytics.</p>
						</HelpButton>
					</div>
				</div>
				{ linkURL.answers.map(createAnswerField) }
				<div className="edit-panel-field">
					<button className="add-answer-button" onClick={addAnswer}>Add another option</button>
				</div>
				<div className="edit-panel-field">
					<Checkbox checked={linkURL.requireEmail} onChange={requireEmailChange}>Collect visitor's email to know who responded to this question</Checkbox>
				</div>
			</>
		);
	} else {
		// Show the promo message
		return (
			<div>
				<p>Asking a Question allows you to get interactive responses and engagement from your audience to generate leads. The responses will be sent directly to your inbox and accessible in your analytics.</p>
				<p><a href="/my-account/billing/#change-my-plan" style={{ color: "white" }}>Upgrade to a paid plan</a> to use this feature.</p>
			</div>
		);
	}
});
 
export default LinkURLQuestionEditFields;
 