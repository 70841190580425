import React from 'react';
import { observer } from "mobx-react-lite";
import VideoPlayer from './VideoPlayer';
import CTAButton from './CTAButton';

const VideoDisplayPopup = observer(({ bioStore, interfaceStateStore, theme, themeProperties, displayMode, serverSynchronization }) => {
	function dismissOverlay() {
		interfaceStateStore.setViewingVideo(false);	
	}
	if(!interfaceStateStore.viewingVideo) {
		return null;
	} else {
		return <div className="video-display-popup" onClick={dismissOverlay}>
			<div className="video-row">
				<VideoPlayer url={serverSynchronization.getMediaURL("video", "", "mp4", bioStore.videoRevisionNumber)} autoPlay="true" playsinline="true"></VideoPlayer>
			</div>
			<div className="cta-button-row">
				<CTAButton bioStore={bioStore} interfaceStateStore={interfaceStateStore} theme={theme} themeProperties={themeProperties} displayMode={displayMode} serverSynchronization={serverSynchronization} actionType="video-cta-button"></CTAButton>
			</div>
			<i className="fas fa-times-circle video-display-close-button" onClick={dismissOverlay}></i>
		</div>
	}
});
 
export default VideoDisplayPopup;