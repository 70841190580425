import React from 'react';
import { observer } from "mobx-react-lite";

const SaveStateLabel = observer(({ interfaceStateStore }) => {
	if(!interfaceStateStore.isEditing) return null;

	function getText() {
		switch(interfaceStateStore.saveState) {
			case "saving":
				return "Saving..."; 
			case "saved":
				return "BIO SAVED.";
			case "error":
				return "Unable to save, check connection.";
			default:
				return "";
		}	
	}
	
	return (
		<div className={"save-state-label save-state-label-" + interfaceStateStore.saveState}>
			{getText()}
		</div>
	);
});
 
export default SaveStateLabel;
 