import React from 'react';
import { observer } from "mobx-react-lite";

const ServerSyncProgressOverlay = observer(({ interfaceStateStore }) => {
	if(interfaceStateStore.serverSyncInProgressType === null) return null;
	let message = "";
	if(interfaceStateStore.serverSyncInProgressType === "cardPhotoSwap") {
		message = "Moving...";
	} else if(interfaceStateStore.serverSyncInProgressType === "fileDelete" || interfaceStateStore.serverSyncInProgressType === "cardPhotoDelete") {
		message = "Deleting...";
	} else if(interfaceStateStore.serverSyncInProgressType === "setBioURL") {
		message = "Publishing your link..."		
	} else {
		message = `Uploading ${interfaceStateStore.serverSyncInProgressType} (time for a dance party)`;
	}
	return (
		<div className="server-sync-progress-overlay">
			<h1>{message}</h1>
		</div>
	);
});
 
export default ServerSyncProgressOverlay;