import React from 'react';

const TreatPhoneNumberAsSMSPrompt = ({ value, onChange }) => {
	return (
		<label className="treat-phone-number-as-sms-prompt">
			Please select if you would like to receive
			<select value={value ? "sms" : "phone"} onChange={(evt) => onChange(evt.target.value === "sms")}>
				<option value="phone">phone calls</option>
				<option value="sms">texts</option>
			</select>
			 with this link.
		</label>
	);
};
 
export default TreatPhoneNumberAsSMSPrompt;
 