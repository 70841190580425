import { containsTemplateReplacementBracketedString } from "./BioTemplateUtil";

function cleanPhoneNumber(value) {
	value = value.replace(/[()\- x]/g, ""); // Strip out parens, hyphens, spaces, x's
	value = value.replace(/^tel:/i, ""); // Strip out an existing tel: scheme if included
	if(/^[0-9p+]+$/.test(value)) return value; // If it's now only numbers, plus signs, and "p"'s for pausing, consider it a telephone number
	return null;
}

function testEmail(str) {
	return /\S+@\S+\.\S+/.test(str); // Crude "looks like a standard email address" expression
}

function isConsideredPhoneNumber(value) {
	if(!value) return false;
	value = value.trim();	
	if(value === '#' || value === '/') return false; // Special case for relative URLs
	return !!cleanPhoneNumber(value) && value.length >= 7;
}

function cleanUserProvidedLink(link, treatPhoneNumberAsSMS = false) {
	if(!link) return null;
	if(typeof(link) !== "string") return link;
	link = link.trim();
	if(link === '#' || link === '/') return link; // Special case for relative URLs
	
	if(containsTemplateReplacementBracketedString(link)) return null; // Special case for template-looking URLs
	
	// First, handle likely-phone-number URLs since they won't work with the parse_url approach we'll take for other URLs
	if(isConsideredPhoneNumber(link)) {
		if(browserLikelyToSupportPhoneFeatures(treatPhoneNumberAsSMS)) {
			const protocol = treatPhoneNumberAsSMS ? "sms:" : "tel:";
			return protocol + cleanPhoneNumber(link);
		} else {
			// Their browser is unlikely to support phone features, so just show a prompt telling them to call/text
			const verb = treatPhoneNumberAsSMS ? "text" : "call";
			return `javascript:alert("Please ${verb} ${link} to continue.");`;
		}
	}
	
	const hasScheme = /^https:\/\/|^http:\/\/|^ftp:\/\/|^mailto:/i.test(link);
	// Second, see if it's a bare email, and change it to a mailto: link.
	if(!hasScheme && testEmail(link)) return "mailto:" + link;

	// If it doesn't have a scheme, provide https as a reasonable default. At some point should intelligently handle checking for other schemes. 
	if(!hasScheme) {
		link = "https://" + link;
	}
	
	// Attempt to parse the URL
	let url = null;
	try {
		url = new URL(link);
	} catch {
		return null;	
	}
	
	if(url.protocol === "mailto") {
		if(testEmail(url.pathname)) return url.toString(); // As long as the path is an email address, we're happy with the URL
	} else {
		return url.toString(); // For now, just accept all other URLs
	}
}

// sms can be false for phone calls, true for sms's
function browserLikelyToSupportPhoneFeatures(sms) {
	let support = true;
	const ua = navigator.userAgent.toLowerCase();
	if(ua.indexOf("instagram") > -1) {
		support = true; // If we're in the Instagram app's embedded browser, phone features are likely supported
	} else if(ua.indexOf("mac os") > -1) {
		if(sms) {
			support = (ua.indexOf("safari") > -1 || ua.indexOf("chrome") > -1 ); // Safari and Chrome currently offer to open Messages on desktop, or will open directly on mobile
		} else {
			support = true; // The link will likely open in Facetime on desktop, or directly on mobile
		}
	} else if(ua.indexOf("windows") > -1 || ua.indexOf("linux") > -1) {
		support = false;
	}	
	return support;
}

export { cleanUserProvidedLink, isConsideredPhoneNumber, testEmail };