import React from 'react';
import { observer } from "mobx-react-lite";
import { Modal } from 'rsuite';
import promoVideo from './videos/pro-promo.mp4';

const VideoPromoPopup = observer(({interfaceStateStore, serverSynchronization}) => {
	function dismiss(e) {
		interfaceStateStore.setShowingVideoPromoPopup(false);
	}
	function upgradeClick() {
		serverSynchronization.upgradeToPro();
	}

	return (
		<React.Fragment>
			<Modal show={interfaceStateStore.showingVideoPromoPopup} className="edit-panel video-promo-popup" overflow={false}>
				<Modal.Body className="edit-panel-body">
					<div className="edit-panel-help">
						<video className="pro-promo-video" src={promoVideo} width="270" height="480" controls="true" autoplay="true" playsinline="true"></video>
					</div>
					<h2>Ready to unlock all the goodness of Pro?</h2>
				</Modal.Body>
				<Modal.Footer>
					<button onClick={dismiss}>Done</button>
					<button onClick={upgradeClick} className="upgrade-to-pro-button">Upgrade to Pro</button>
				</Modal.Footer>
			</Modal>
		</React.Fragment>
	);
});
 
export default VideoPromoPopup;
