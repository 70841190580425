import React from 'react';
import { observer } from "mobx-react-lite";
import EditPanel from './EditPanel';
import { Input } from 'rsuite';
import TreatPhoneNumberAsSMSPrompt from './TreatPhoneNumberAsSMSPrompt';
import { isConsideredPhoneNumber } from './URLCleanupUtil';
import { linkURLTypeString } from './Stores';
import LinkURLQuestionEditFields from './LinkURLQuestionEditFields';
import HelpButton from './HelpButton';
import LinkTypeDropdown from './LinkTypeDropdown';
import LinkURLUploadEditFields from './LinkURLUploadEditFields';

const CTAButtonEditPanel = observer(({ bioStore, interfaceStateStore, serverSynchronization }) => {
	function linkURLTypeChange(value) {
		bioStore.changeCTAButtonURLType(value);
	}
	function labelChange(value) {
		bioStore.setCTAButtonLabel(value);
	}
	function urlChange(content) {
		bioStore.setCTAButtonURL(content);
	}
	function treatPhoneNumberAsSMSChange(value) {
		bioStore.setCTAButtonTreatPhoneNumberAsSMS(value);	
	}
	function deleteContent() {
		bioStore.setCTAButtonLabel("");
		bioStore.setCTAButtonURL("");
		bioStore.setCTAButtonTreatPhoneNumberAsSMS("");
		interfaceStateStore.setCurrentlyEditing(null, null, null);
	}
	function createURLControls(url, treatPhoneNumberAsSMS) {
		return (
			<div className="edit-panel-field">
				<label>Button Link</label>
				<div className="input-with-side-button">
					<Input value={url} onChange={urlChange} />
					<HelpButton>
						<p>Link to anything! As long as it has a URL you can link to it! Websites, Canva links, Venmo, Youtube, Google Docs, Google Share, Google Forms, Email lists, Sign up forms, PDFs, Email address, AND a phone number to text or call.</p>
					</HelpButton>
				</div>
				{ isConsideredPhoneNumber(url) && <TreatPhoneNumberAsSMSPrompt value={treatPhoneNumberAsSMS} onChange={treatPhoneNumberAsSMSChange}></TreatPhoneNumberAsSMSPrompt> }
			</div>	
		);
	}
	const url = bioStore.ctaButtonURL || "";
	const urlTypeString = linkURLTypeString(url);
	const treatPhoneNumberAsSMS = bioStore.ctaButtonTreatPhoneNumberAsSMS;
	
	let linkURLSettings = null;
	switch(urlTypeString) {
		case "url":
			linkURLSettings = createURLControls(url, treatPhoneNumberAsSMS);
			break;
		case "question":
			linkURLSettings = <LinkURLQuestionEditFields linkURL={url} hasPaidPlan={interfaceStateStore.hasPaidPlan}></LinkURLQuestionEditFields>;
			break;
		default:
			linkURLSettings = <LinkURLUploadEditFields linkURL={url} hasPaidPlan={interfaceStateStore.hasPaidPlan} serverSynchronization={serverSynchronization}></LinkURLUploadEditFields>;
			break;
	}
	
	return (
		<EditPanel
			bioStore={bioStore}
			interfaceStateStore={interfaceStateStore}
			itemType="cta-button"
			title="Hot Button"
			helpText={{
				'bio': 'Add your most important link here to encourage action! This is your most prominent button that is displayed at the top within your introduction and included within your IntroClip Video.',
				'quickclick': 'Place your most important link here so that your guests see it first. This button also shows up when your guests view your IntroClip Video.',
				'quiz': 'Place your first question here so that your visitors see it first. This button also shows up when your guests view your IntroClip Video.',
				'informational': 'Add your most important link here to encourage action! This is your most prominent button that is displayed at the top within your introduction and included within your IntroClip Video.'
			}}
			showDelete={true}
			onDelete={deleteContent}
			showTipsButton={true}
		>
			<div className="edit-panel-field">
				<label>Button Title:</label>
				<Input value={bioStore.ctaButtonLabel || ""} onChange={labelChange} />
			</div>
			<LinkTypeDropdown url={url} onChange={linkURLTypeChange}></LinkTypeDropdown>
			{ linkURLSettings }
		</EditPanel>
	);
});
 
export default CTAButtonEditPanel;
 