import { observer } from 'mobx-react-lite';
import React from 'react';
import HelpButton from './HelpButton';

const MaxFileLength = 20000000;

const LinkURLUploadEditFields = observer(({ linkURL, hasPaidPlan, serverSynchronization }) => {
	function handleUpload(e) {
		const input = e.target;
		if(input.files && input.files.length > 0) {
			const file = input.files[0];
			if(file.size && file.size > MaxFileLength) {
				alert("We're sorry, that file size is too large. Please choose a file that's less than 19MB.")
			} else {
				serverSynchronization.uploadFile(file, data => {
					linkURL.setUploadData(data.id, data.filename, data.fileType);
				});
			}
		}
	}
	function deleteHandler() {
		if(window.confirm('Are you sure you want to delete this file?')) {
			serverSynchronization.deleteFile(linkURL.id, () => {
				linkURL.setUploadData(null, null, null);
			});
		}
	}
	
	if(hasPaidPlan) {
		return (
			<>
				<div className="edit-panel-field" style={{ marginTop: "1.3em", marginBottom: "1.3em" }}>
					<div className="input-with-side-button">
						{ linkURL.id || <input type="file" onChange={handleUpload} accept=".png, .jpg, .jpeg .mpg, .mpeg, .mp4, .mov, .pdf" /> }
						{ linkURL.id && <div className="filename">{linkURL.filename}</div> }
						{ linkURL.id && <button className="delete-button" onClick={deleteHandler}>x</button> }
						<HelpButton>
							<p>Upload your videos, images, and PDFs for easy access by your bio visitors.</p>
							<p>Video Requirements:</p>
							<ol>
								<li>File format is .mpg, .mpeg, mp4, or .mov</li>
								<li>File size is under 19mb</li>
							</ol>
							<p>*See <a href="/help-center" target="_blank">Help Center</a> for questions.</p>
						</HelpButton>
					</div>
				</div>
			</>
		);
	} else {
		// Show the promo message
		return (
			<div>
				<p>Upload your videos, images, and PDFs for easy access by your bio visitors.</p>
				<p><a href="/my-account/billing/#change-my-plan" style={{ color: "white" }}>Upgrade to a paid plan</a> to use this feature.</p>
			</div>
		);
	}
});
 
export default LinkURLUploadEditFields;
 