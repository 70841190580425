import React from 'react';
import { observer } from "mobx-react-lite";
import Bio from './Bio';
import {Themes, ThemeProperties, RetiredThemes} from './Stores';

const ThemeGallery = observer(({bioStore, interfaceStateStore, serverSynchronization}) => {
	function close() {
		interfaceStateStore.setThemeGalleryOpen(false);
	}
	function createBio(themeDataItem) {
		function themeSelected() {
			interfaceStateStore.setPreviewingTheme(themeDataItem.theme, themeDataItem.isOverriddenTheme);
			interfaceStateStore.setThemeGalleryOpen(false); 
			window.scrollTo(0, 0);
		}
		
		const themeProperties = ThemeProperties[themeDataItem.theme];
		const name = themeDataItem.isOverriddenTheme ? interfaceStateStore.cloneSourceThemeTitle : themeProperties.name;
		let badge = null;
		if(!interfaceStateStore.hasPaidPlan) {
			badge = <div className="theme-gallery-availability-badge">{ themeProperties.isFree ? "Free" : "Paid" }</div>;
		}
		return (
			<div className="theme-gallery-bio-container" key={themeDataItem.theme}>
				<h3>{name}{badge}</h3>
				<Bio bioStore={bioStore} interfaceStateStore={interfaceStateStore} theme={themeDataItem.theme} isOverriddenTheme={themeDataItem.isOverriddenTheme} themeProperties={themeProperties} displayMode="previewing" onClick={themeSelected} serverSynchronization={serverSynchronization}></Bio>
			</div>
		)
	}
	
	// Filter out retired and beta-only themes
	let filteredThemes = Themes.filter(theme => RetiredThemes.indexOf(theme) === -1);
	// if(!interfaceStateStore.showBetaFeatures) filteredThemes = filteredThemes.filter(theme => theme !== "fresh-ambition");
	
	const themeData = filteredThemes.map( themeName => { return { "theme": themeName, "isOverriddenTheme": false } } );

	// Add the overridden theme at the beginning as an option if relevant
	if(interfaceStateStore.cloneSourceThemeTitle) themeData.unshift({ "theme": interfaceStateStore.cloneSourceTheme, "isOverriddenTheme": true }); 
	return (
		<div className="theme-gallery">
			<div className="theme-gallery-header">
				<div className="theme-gallery-header-buttons">
					<button onClick={close}>Close</button>
				</div>
				<h2>Care to try on a new look? Scroll through the gallery to view what your site looks like in a different theme. If you see one you like, just select the theme and then click on "Pick this theme" and your look will easily change.</h2>
			</div>
			<div className="theme-gallery-bios">
				{ themeData.map(themeDataItem => createBio(themeDataItem)) }
			</div>
		</div>
	);
});

export default ThemeGallery;
