import introPhoto from './intro-photo.jpg';
import contactAccentPhoto from './contact-accent-photo.jpg';
import carouselCardPhoto1 from './carousel-card-photo-1.jpg';
import carouselCardPhoto2 from  './carousel-card-photo-2.jpg';
import carouselCardPhoto3 from  './carousel-card-photo-3.jpg';
import carouselPreviousButtonIcon from  './carousel-previous-button-icon.png';
import carouselNextButtonIcon from  './carousel-next-button-icon.png';
import testimonialPhoto from './testimonial-photo.jpg';

const themeProperties = {
	"name": "Fresh Ambition",
	"isFree": false,
	"introPhoto": introPhoto,
	"contactAccentPhoto": contactAccentPhoto,
	"linkIcon": undefined,
	"ctaButtonIcon": undefined,
	"carouselSpaceBetween": 0,
	"carouselSlidesPerViewDesktop": 3,
	"carouselSlidesPerViewMobile": 1,
	"carouselCardPhotos": [carouselCardPhoto1, carouselCardPhoto2, carouselCardPhoto3],
	"carouselDefaultCards": [
		{
			"heading": "Shop My Faves",
			"body": "This is the place to grab your reader’s attention with an eye-catching image, a short description, and link to a helpful resource.",
			"buttonLabel": "Shop Now",
			"buttonURL": undefined 
		},
		{
			"heading": "Essential Reading",
			"body": "This is the place to grab your reader’s attention with an eye-catching image, a short description, and link to a helpful resource.",
			"buttonLabel": "Learn More",
			"buttonURL": undefined 
		},
		{
			 "heading": "Get My Free Guide",
			 "body": "This is the place to grab your reader’s attention with an eye-catching image, a short description, and link to a helpful resource.",
			 "buttonLabel": "Download",
			 "buttonURL": undefined
		}
	],
	"carouselCardButtonIcon": undefined,
	"carouselPreviousButtonIcon": carouselPreviousButtonIcon,
	"carouselNextButtonIcon": carouselNextButtonIcon,
	"testimonialPhoto": testimonialPhoto,
	"usesFlipButtons": true
};

export default themeProperties;