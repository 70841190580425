import { cleanUserProvidedLink } from "./URLCleanupUtil";
import { toJS } from 'mobx';

function hrefForLinkURL(url, treatPhoneNumberAsSMS, interfaceStateStore, serverSynchronization) {
	if(typeof(url) === "string") {
		return cleanUserProvidedLink(url, treatPhoneNumberAsSMS);
	} else {
		if(!url) return url;
		switch(url.type) {
			case "question":
				return null;
			case "upload":
				if(url.fileType === "pdf" && interfaceStateStore.hasPaidPlan) {
					return serverSynchronization.getUploadDisplayURL(url.id, url.fileType);
				} else {
					return null;
				}
			default:
				return null;
		}
	}
}

function targetForLinkURL(url) {
	if(url && typeof(url) !== "string") {
		if(url.fileType !== "pdf") return null;
	}
	
	return "_blank";
}

function onClickForLinkURL(url, interfaceStateStore, serverSynchronization, typeForLogAction, targetTitleForLogAction) {
	if(typeof(url) === "string") {
		if(typeForLogAction) {
			return () => serverSynchronization.logAction(typeForLogAction, targetTitleForLogAction);
		} else {
			return null;
		}
	} else {
		if(!url) return null;
		switch(url.type) {
			case "question":
				if(interfaceStateStore.isEditing) {
					return () => alert('Your visitor would now be presented with your question, "' + url.questionText  + '"');
				} else {
					if(interfaceStateStore.hasPaidPlan) {
						return () => interfaceStateStore.setShowingQuestionLinkURL(toJS(url));
					} else {
						return null; // Don't allow polls to run on unpaid plans	
					}
				}
			case "upload":
				if(url.fileType === 'pdf') {
					if(typeForLogAction) {
						return () => serverSynchronization.logAction(typeForLogAction, targetTitleForLogAction);
					} else {
						return null;
					}
				} else {
					if(interfaceStateStore.hasPaidPlan) {
						return () => interfaceStateStore.setShowingUpload(url.id, url.fileType);
					} else {
						return null; // Don't allow upload viewing on unpaid plans						
					}
				}
			default:
				return null;
		}
	}
}

export { hrefForLinkURL, onClickForLinkURL, targetForLinkURL };