import React, { useState } from 'react';
import { Modal, Button } from 'rsuite';

const ThemeWasSwitchedToPopup = () => {
	const [show, setShow] = useState(true); // If we've been created, we'll show by default

	return (
		<Modal show={show} className='edit-panel intro-popup' backdrop={true} overflow={false}>
			<Modal.Body className="edit-panel-body">
				<p>Your new theme has been activated!</p>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={() => setShow(false)} appearance="primary" className="done-button">Let's Go</Button>
			</Modal.Footer>
		</Modal>
	)
};
 
export default ThemeWasSwitchedToPopup;
