import React from 'react';
import { observer } from "mobx-react-lite";
import EditButton from './EditButton';
import {contentForDisplayMode} from './Stores';
import {hrefForLinkURL, onClickForLinkURL, targetForLinkURL} from './URLDisplayUtil';

const CTAButton = observer(({ bioStore, interfaceStateStore, theme, themeProperties, displayMode, serverSynchronization, actionType }) => {
	const label = contentForDisplayMode(displayMode, bioStore.ctaButtonLabel, "Learn More");
	const url = bioStore.ctaButtonURL; // We're not going to contentForDisplayMode on this because sample URLs aren't useful
	const treatPhoneNumberAsSMS = bioStore.ctaButtonTreatPhoneNumberAsSMS;
		
	const hasContent = !!label;
	let editButton = null;
	if(displayMode === "editing") {
		editButton = (
			<EditButton
				addLabel="Add Hot Button"
				isAdd={!hasContent}
				onClick={()  => interfaceStateStore.setCurrentlyEditing("cta-button", null, null)}
			>
			</EditButton>
		);
	}

	
	if(hasContent) {
		const ctaButtonIcon = themeProperties.ctaButtonIcon;
		const iconElement = ctaButtonIcon && <img src={ctaButtonIcon} className="cta-button-icon" alt=""></img>;
		return (
			<React.Fragment>
				<a
					className="cta-button"
					href={hrefForLinkURL(url, treatPhoneNumberAsSMS, interfaceStateStore, serverSynchronization)}
					target={targetForLinkURL(url)}
					onClick={onClickForLinkURL(url, interfaceStateStore, serverSynchronization, "click-" + actionType, label)}
					rel="noreferrer"
					data-front={label}
					data-back={label}
				>
					{themeProperties.usesFlipButtons ? "" : label}
					{iconElement}
					{editButton}
				</a>
			</React.Fragment>
		);
	} else {
		return editButton;
	}
});
 
export default CTAButton;
 