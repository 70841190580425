import {createCookie} from './CookieUtil';

function themeSelectedForCheckout(theme) {
	createCookie("selectedTheme", theme); // Save their selected theme so it can be retrieved later when the bio editor is loaded
	window.location = (theme === "basic") ? "/go-basic/" : "/choose-plan/";
}

function activateMyBioWithThemeTheme(interfaceStateStore) {
	createCookie("selectedThemeToSwitchTo", interfaceStateStore.myBioWithTheme); // Save their selected theme so it can be retrieved later when the bio editor is loaded
	if(interfaceStateStore.hasPaidPlan) {
		// They have Pro, so they already have access to the theme, meaning we can just load up the editor version
		window.location.href = window.location.origin + window.location.pathname + "?edit";
	} else {
		createCookie("autoShowEditor", "true"); // We want to automatically open the editor after they check out
		window.location = "/choose-plan/";
	}
}

export { themeSelectedForCheckout, activateMyBioWithThemeTheme };