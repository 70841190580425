import React from 'react';
import { observer } from "mobx-react-lite";
import EditButton from './EditButton';
import CarouselCard from './CarouselCard';
import {contentForDisplayMode, photoSlotForCardIndex} from './Stores';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import "swiper/components/pagination/pagination.min.css"

SwiperCore.use([Navigation, Pagination]);

const CarouselRow = observer(({ bioStore, interfaceStateStore, theme, themeProperties, displayMode, serverSynchronization }) => {
	function createCardElement(card, index) {
		const key = card.id || index;
		const defaultPhotoURL = (displayMode === "editing") ? null : themeProperties.carouselCardPhotos[index];
		const photoSlot = photoSlotForCardIndex(index);
		return (
			<SwiperSlide key={key}>
				<CarouselCard bioStore={bioStore} interfaceStateStore={interfaceStateStore} id={card.id} defaultPhotoURL={defaultPhotoURL} heading={card.heading} body={card.body} buttonLabel={card.buttonLabel} buttonURL={card.buttonURL} treatPhoneNumberAsSMS={card.treatPhoneNumberAsSMS} theme={theme} themeProperties={themeProperties} displayMode={displayMode} serverSynchronization={serverSynchronization} photoSlot={photoSlot}></CarouselCard>
			</SwiperSlide>
		);
	}
	
	// If bioStore.carouselCards is an empty array instead of null, it will be used, which is what we want: the user has edited cards and "selected" zero
	let cards = contentForDisplayMode(displayMode, bioStore.carouselCards, themeProperties.carouselDefaultCards);
	if(cards === null) cards = [];
	const cardElements = cards.map(createCardElement);
	let addCardElementRow = null;
	if(displayMode === "editing" && cards.length < 3) {
		let label = ""; 
		switch(cards.length) {
			case 0: label = "Add 1st Carousel"; break;
			case 1: label = "Add 2nd Carousel"; break;
			case 2: label = "Add 3rd Carousel"; break;
			default: label = "Add Carousel"; break;
		}
		addCardElementRow = (
			<div className="carousel-add-row">
				<EditButton
					addLabel={label}
					isAdd={true}
					onClick={() => {
						const newCardID = bioStore.addCarouselCard("", "", "", "", "");
						const photoSlot = photoSlotForCardIndex(bioStore.carouselCards.length - 1);
						interfaceStateStore.setCurrentlyEditing("carousel-card", newCardID, photoSlot);
					}}>
				</EditButton>
			</div>
		);
	}
	return (
		<div className="carousel-row">
			<div className="carousel-column">
				<Swiper className="carousel-cards"
					pagination={{ clickable: true, hideOnClick: false}}
					watchOverflow={true}
					spaceBetween={themeProperties.carouselSpaceBetween}
					slidesPerView={themeProperties.carouselSlidesPerViewMobile}
					navigation={{prevEl: ".carousel-previous-button", nextEl: ".carousel-next-button"}}
					observer={true} // Necessary so that it updates in response to our dynamic slide content changes
					breakpoints={{
						576: { // Match our CSS mobile/desktop breakpoint
							slidesPerView: themeProperties.carouselSlidesPerViewDesktop
						},
					}}
					>
						<div className="carousel-previous-button"><img src={themeProperties.carouselPreviousButtonIcon} alt="Next button"></img></div>
						<div className="carousel-next-button"><img src={themeProperties.carouselNextButtonIcon} alt="Previous button"></img></div>
						{cardElements}
				</Swiper>
				{addCardElementRow}
			</div>
		</div>
	);
});
 
export default CarouselRow;