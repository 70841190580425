import { observer } from "mobx-react-lite";
import React from 'react';
import SignupButton from './images/footer-signup-button.png';

const Footer = observer(({ bioStore, interfaceStateStore, displayMode }) => {
	if(interfaceStateStore.shoppingTheme || interfaceStateStore.previewingTheme || interfaceStateStore.themeGalleryOpen) return null; // Leave out the footer when they're shopping or previewing
	if(bioStore.hideFooterBranding) return null;
	
	const shopURL = interfaceStateStore.isEditing ? "#" : "/?utm_medium=onsite&utm_source=bio-footer&utm_campaign=tap-to-build-a-bosslady-bio";
		
	return (
		<footer>
				<a
					href={shopURL}
					onClick={() => {
						window.ga && window.ga('send', 'event', 'Link', 'click', 'bio-footer-signup');
					} }
					target="_blank"
					rel="noreferrer"
				>
					<img src={SignupButton} alt="HOW DO I GET A LINK"></img>
				</a>
		</footer>
	);
});
 
export default Footer;