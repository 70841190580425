// From https://stackoverflow.com/questions/56934826/distinguish-between-ipad-and-mac-on-ipad-with-ipados since we need to detect whether they're on an iOS device that will handle VCards in a way that won't confuse users compared to desktop browsers. And Safari on the iPad now appears as a Mac user-agent-wise.
function isMobile() {
  let isMobile = RegExp(/Android|webOS|iPhone|iPod|iPad/i)
   .test(navigator.userAgent);

  if (!isMobile) {
    const isMac = RegExp(/Macintosh/i).test(navigator.userAgent);

    if (isMac && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
      isMobile = true;
    }
  }
  return isMobile;
}

export { isMobile };