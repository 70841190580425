import React from 'react';
import { observer } from "mobx-react-lite";
import EditPanel from './EditPanel';
import SunEditor from 'suneditor-react';
import { Input } from 'rsuite';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
const TagWhitelist = "b|i|u|a|em|strong|p|br";
const AttributesWhitelist = {
	a: 'href'
  }

const IntroTextEditPanel = observer(({ bioStore, interfaceStateStore }) => {
	function headingChange(value) {
		bioStore.setHeading(value);
	}
	function bodyChange(content) {
		bioStore.setBody(content);
	}
	function deleteContent() {
		bioStore.setHeading("");
		bioStore.setBody("");
		interfaceStateStore.setCurrentlyEditing(null, null, null);
	}
	return (
		<EditPanel
			bioStore={bioStore}
			interfaceStateStore={interfaceStateStore}
			itemType="intro-text"
			title="Introduction"
			helpText={{
				'bio': "Capture your visitors attention with a powerful introduction.",
				'quickclick': 'Introduce yourself and write a welcome message with clear directions for your guests.',
				'quiz': "Introduce yourself and write a welcome message with clear directions for your visitors.",
				'informational': "Capture your visitors attention with a powerful introduction."
			}}
			proTipText={{
				'bio': '2-3 sentences is ideal.',
				'quickclick': "Don’t leave any room for guessing. Your guests will have an excellent experience if you lead them every step of the way.",
				'quiz': 'Don’t leave any room for guessing. Your visitors will have an excellent experience if you lead them every step of the way.',
				'informational': '2-3 sentences is ideal.'
			}}
			showDelete={true}
			onDelete={deleteContent}
			deleteLabel="Clear this section"
			showTipsButton={true}
		>
			<div className="edit-panel-field">
				<label>Headline:</label>
				<Input value={bioStore.heading || ""} onChange={headingChange} />
			</div>
			<div className="edit-panel-field">
				<SunEditor height="auto" width="100%" setContents={bioStore.body || ""} setOptions={{buttonList: [['bold', 'underline', 'italic'],['link']], resizingBar: false, showPathLabel: false, pasteTagsWhitelist: TagWhitelist, attributesWhitelist: AttributesWhitelist, addTagsWhitelist: TagWhitelist}} onChange={bodyChange} />
			</div>
		</EditPanel>
	);
});
 
export default IntroTextEditPanel;
 