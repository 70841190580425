import React, { useState } from 'react';

const HelpButton = ({children}) => {
	const [opened, setOpened] = useState(false);

	let content = null;
	if(opened) {
		content	= (
			<div className="help-button-content">
				{children}
			</div>
		);
	}
	
	return (
		<>
			<button className="help-button" onClick={() => setOpened(!opened)}>
				<i className="fas fa-question-circle"></i>	
			</button>
			{content}
		</>
	)
};
 
export default HelpButton;
