import React from 'react';

const ProTip = ({text}) => {
	return (
		<figure className="pro-tip">
			<i className="fas fa-lightbulb"></i>
			<figcaption>
				<span className="pro-tip-label">Pro Tip:</span>
				{" " + text}
			</figcaption>
	  	</figure> 
	)
};
 
export default ProTip;
