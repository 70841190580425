import React from 'react';
import IntroTextEditPanel from './IntroTextEditPanel';
import LinkEditPanel from './LinkEditPanel';
import ContactDetailsEditPanel from './ContactDetailsEditPanel';
import ContactSocialMediaListEditPanel from './ContactSocialMediaListEditPanel';
import IntroPhotoEditPanel from './IntroPhotoEditPanel';
import CTAButtonEditPanel from './CTAButtonEditPanel';
import CarouselCardEditPanel from './CarouselCardEditPanel';
import TestimonialEditPanel from './TestimonialEditPanel';
import VideoEditPanel from './VideoEditPanel';
import ServerSyncProgressOverlay from './ServerSyncProgressOverlay';

const EditPanels = ({ bioStore, interfaceStateStore, serverSynchronization }) => {
	if(!interfaceStateStore.isEditing) return null;

	return (
		<React.Fragment>
			<IntroPhotoEditPanel bioStore={bioStore} interfaceStateStore={interfaceStateStore} serverSynchronization={serverSynchronization}></IntroPhotoEditPanel>
			<IntroTextEditPanel bioStore={bioStore} interfaceStateStore={interfaceStateStore}></IntroTextEditPanel>
			<CTAButtonEditPanel bioStore={bioStore} interfaceStateStore={interfaceStateStore} serverSynchronization={serverSynchronization}></CTAButtonEditPanel>
			<LinkEditPanel bioStore={bioStore} interfaceStateStore={interfaceStateStore} serverSynchronization={serverSynchronization}></LinkEditPanel>
			<CarouselCardEditPanel bioStore={bioStore} interfaceStateStore={interfaceStateStore} serverSynchronization={serverSynchronization}></CarouselCardEditPanel>
			<TestimonialEditPanel bioStore={bioStore} interfaceStateStore={interfaceStateStore} serverSynchronization={serverSynchronization}></TestimonialEditPanel>
			<ContactDetailsEditPanel bioStore={bioStore} interfaceStateStore={interfaceStateStore} serverSynchronization={serverSynchronization}></ContactDetailsEditPanel>
			<ContactSocialMediaListEditPanel bioStore={bioStore} interfaceStateStore={interfaceStateStore}></ContactSocialMediaListEditPanel>
			<VideoEditPanel bioStore={bioStore} interfaceStateStore={interfaceStateStore} serverSynchronization={serverSynchronization}></VideoEditPanel>
			<ServerSyncProgressOverlay interfaceStateStore={interfaceStateStore}></ServerSyncProgressOverlay>
		</React.Fragment>
	);
};
 
export default EditPanels;
 