import React from 'react';
import { observer } from "mobx-react-lite";
import { Modal, Button } from 'rsuite';
import ProTip from './ProTip';
import TipsButton from './TipsButton';

const EditPanel = observer(({
	bioStore,
	interfaceStateStore,
	children,
	itemType,
	title = "",
	showDelete = false,
	deleteLabel = "Delete",
	onDelete, onDone,
	helpText, // Either a string or a map from primaryGoal => string
	className,
	additionalMetaControls = null,
	proTipText, // Either a string or a map from primaryGoal => string
	showTipsButton = false
}) => {
	function textFromStringOrPrimaryGoalMap(val) {
		if(!val) return null;
		return typeof(val) === "string" ? val : val[interfaceStateStore.primaryGoal];
	}
	
	onDone = onDone || (() => interfaceStateStore.setCurrentlyEditing(null, null, null));
	const deleteHandler = () => { 
		if(onDelete && window.confirm("Are you sure?")) onDelete();
	}

	const showPanel = (interfaceStateStore.currentlyEditingItemType === itemType);
	const deleteButton = showDelete ? <Button onClick={deleteHandler} className="delete-button">{deleteLabel}</Button> : null;
	const helpTextValue = textFromStringOrPrimaryGoalMap(helpText);
	const helpTextContent = helpTextValue ? <p>{helpTextValue}</p> : null;
	const proTipTextValue = textFromStringOrPrimaryGoalMap(proTipText);
	const proTipContent = proTipTextValue ? <ProTip text={proTipTextValue}></ProTip> : null;

	return (
		<Modal show={showPanel} className={`edit-panel ${className || ''}`} overflow={false}>
			<Modal.Body className="edit-panel-body">
				<h1>{title}</h1>
				<div className="edit-panel-help">
					{ helpTextContent }
					{ proTipContent }
					{ showTipsButton && <TipsButton primaryGoal={interfaceStateStore.primaryGoal} itemType={itemType}/> }
					<hr></hr>
				</div>
				{children}
			</Modal.Body>
			<Modal.Footer className="edit-panel-meta-controls">
				{deleteButton}
				{additionalMetaControls}
				<Button onClick={onDone} appearance="primary">Done</Button>
			</Modal.Footer>
		</Modal>
	)
});
 
export default EditPanel;
