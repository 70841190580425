import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BioStore, InterfaceStateStore, sanitizeThemeValue} from './Stores';
import { onPatch, getSnapshot } from 'mobx-state-tree';
import ServerSynchronization from './ServerSynchronization';
import {readCookie, eraseCookie} from './CookieUtil';
import {themeSelectedForCheckout} from './SelectedThemeCookieUtil';

let params = new URLSearchParams(window.location.search);
let theme = "basic";
let hasPaidPlan = document.config.hasPaidPlan;
let isEditing = document.config.isEditing;
let previewingTheme = null;
let shoppingTheme = document.config.shoppingTheme;
let primaryGoal = document.config.primaryGoal;
if(document.config.buyNow && shoppingTheme) { // If they're buying without previewing, set their selected theme cookie and send them to checkout immediately
   themeSelectedForCheckout(shoppingTheme);
} else { 
  if(document.debug) {
    if(params.has("theme")) theme = params.get("theme");
    if(params.has("edit")) isEditing = true;
    if(params.has("previewingTheme")) { previewingTheme = theme; window.document.title = theme };
    if(params.has("shoppingTheme")) shoppingTheme = theme;
    if(params.has("primaryGoal")) primaryGoal = params.get("primaryGoal");
  } 
  let initialBioData;
  let firstRun = false;
  if(document.config.bioData) {
    initialBioData = document.config.bioData;
    firstRun = (document.config.revisionNumber || 0) <= 1;
  } else {
    if(document.debug) {
      firstRun = false; // Skip tours during testing
    } else {
      firstRun = true; // We don't have bio data, so it's the first run
    }
    const cookieTheme = sanitizeThemeValue(readCookie("selectedTheme"));
    if(cookieTheme) {
      theme = cookieTheme;
      eraseCookie("selectedTheme");
    }
    initialBioData = { schemaVersion: 1, theme: theme };
  }
  // If they don't have Pro, make sure they're not set to a Pro theme
  if(!hasPaidPlan) {
    theme = "basic";
    initialBioData.theme = "basic";
  }
  const userLevel = document.config.userLevel;
  const womanOwnedBusiness = document.config.womanOwnedBusiness;
  const bioAuthorName = document.config.bioAuthorName;
  const showBetaFeatures = document.config.showBetaFeatures;
  const myBioWithTheme = document.config.myBioWithTheme;
  const availableDomains = document.config.availableDomains.split(",");
  const isCloneSource = document.config.isCloneSource;
  const cloneSourceTheme = document.config.cloneSourceTheme;
  const cloneSourceThemeTitle = document.config.cloneSourceThemeTitle;
  let introState = "none";
  if(firstRun) {
    introState = cloneSourceTheme ? "clone" : "normal"; // We'll show the clone intro on first-run sessions where the clone source has been set
  }
  delete document.config.bioData;

  const bioStore = BioStore.create(initialBioData);
  const interfaceStateStore = InterfaceStateStore.create({ hasPaidPlan: hasPaidPlan, isEditing: isEditing, themeGalleryOpen: false, previewingTheme: previewingTheme, shoppingTheme: shoppingTheme, serverSyncInProgressType: null, viewingVideo: false, showingVideoPromoPopup: false, showingSharePopup: false, firstRun: firstRun, previewingForShare: false, userLevel: userLevel, womanOwnedBusiness: womanOwnedBusiness, bioAuthorName: bioAuthorName, showBetaFeatures: showBetaFeatures, myBioWithTheme: myBioWithTheme, themeWasSwitchedTo: false, viewedVideo: false, primaryGoal: primaryGoal, hasSetURL: document.config.hasSetURL, hasSharedViaEmail: document.config.hasSharedViaEmail, hasSharedViaSocial: document.config.hasSharedViaSocial, availableDomains: availableDomains, selectedDomain: document.config.selectedDomain, selectedNicename: document.config.selectedNicename, referralURL: document.config.referralURL, upgradeToProURL: document.config.upgradeToProURL, isCloneSource: isCloneSource, cloneSourceTheme: cloneSourceTheme, cloneSourceThemeTitle: cloneSourceThemeTitle, introState: introState });
  let serverSynchronization = null;
  serverSynchronization = new ServerSynchronization(bioStore, interfaceStateStore, document.config.revisionNumber || 0, document.config.saveURL, document.config.videoUploadURL, document.config.photoUploadURL, document.config.fileUploadURL, document.config.fileDeleteURL, document.config.mediaURL, document.config.uploadDisplayURL, document.config.bioURL, document.config.logViewURL, document.config.logActionURL, document.config.getEditorNeedsReloadingStatusURL, document.config.cardPhotoSwapURL, document.config.cardPhotoDeleteURL, document.config.getVCardURL, document.config.logQuestionAnsweredURL, document.config.setBioURLURL, document.config.setSharedStatusURL);
  
  window.addEventListener('beforeunload', function (e) {
    if(serverSynchronization.isSaved()) {
      delete e['returnValue']; // Allow the unload
    } else {
      e.preventDefault(); // Firefox
      e.returnValue = ''; // Chrome
    }
  });

  // If we need to do a "switch-to" theme, do it now so that ServerSynchronization can save the change
  const switchToTheme = sanitizeThemeValue(readCookie("selectedThemeToSwitchTo"));
  if(switchToTheme) {
    if(interfaceStateStore.hasPaidPlan || switchToTheme === "basic") { // If they're Pro or are switching to Basic, they're allowed to continue
      bioStore.setTheme(switchToTheme, false);
      interfaceStateStore.setThemeWasSwitchedTo(true);
    }
  }
  eraseCookie("selectedThemeToSwitchTo");
  eraseCookie("autoShowEditor");
  
  const root = document.getElementById('root');
  
  // If we're editing or previewing, we need additional margins on the body to accommodate the toolbar height
  if(isEditing || shoppingTheme || myBioWithTheme) {
    root.classList.add("is-editing");
    document.body.classList.add("is-editing");
  }
  
  ReactDOM.render(
    <React.StrictMode>
      <App bioStore={bioStore} interfaceStateStore={interfaceStateStore} serverSynchronization={serverSynchronization} />
    </React.StrictMode>,
    root
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();

  if(document.debug) {
    document.dumpBioStore = function() {
      console.log("BioStore dump", getSnapshot(bioStore));
    };
    
    onPatch(bioStore, newSnapshot => {
      console.dir("bioStore patch: ", newSnapshot)
    });

    onPatch(interfaceStateStore, newSnapshot => {
      console.dir("interfaceStateStore patch: ", newSnapshot)
    });
  }
  
  if(!interfaceStateStore.isEditing) {
    serverSynchronization.logView();
  }
}
