import { observer } from "mobx-react-lite";
import IntroRow from './IntroRow';
import LinksRow from './LinksRow';
import CarouselRow from './CarouselRow';
import TestimonialRow from './TestimonialRow';
import ContactRow from './ContactRow';
import Footer from './Footer';
import EditPanels from './EditPanels';
import VideoDisplayPopup from "./VideoDisplayPopup";
import VideoPromoPopup from './VideoPromoPopup';
import QuestionPopup from "./QuestionPopup";
import ShowingUploadPopup from "./ShowingUploadPopup";

const Bio = observer(({bioStore, interfaceStateStore, theme, isOverriddenTheme, themeProperties, displayMode, onClick, serverSynchronization}) => {
	let classes = 'bio theme-' + theme;
	if(isOverriddenTheme) classes += " overridden-theme-active";
	const editPanels = (displayMode === "editing") && <EditPanels bioStore={bioStore} interfaceStateStore={interfaceStateStore} serverSynchronization={serverSynchronization}></EditPanels>;
	let showPro;
	if(interfaceStateStore.hasPaidPlan) {
		showPro = true; // Pro users always see Pro options	
	} else {
		// Basic users only see Pro options if shopping or previewing Pro themes
		if(theme === "basic") {
			showPro = false;	
		} else {
			showPro = (displayMode === "previewing" || interfaceStateStore.shoppingTheme || interfaceStateStore.myBioWithTheme);
		}
	}
	
	return (
		<div className={classes} onClick={onClick}>
			<IntroRow bioStore={bioStore} interfaceStateStore={interfaceStateStore} theme={theme} themeProperties={themeProperties} displayMode={displayMode} serverSynchronization={serverSynchronization}></IntroRow>
			<LinksRow bioStore={bioStore} interfaceStateStore={interfaceStateStore} theme={theme} themeProperties={themeProperties} displayMode={displayMode} serverSynchronization={serverSynchronization}></LinksRow>
			{ showPro && <CarouselRow bioStore={bioStore} interfaceStateStore={interfaceStateStore} theme={theme} themeProperties={themeProperties} displayMode={displayMode} serverSynchronization={serverSynchronization}></CarouselRow> }
			{ showPro && <TestimonialRow bioStore={bioStore} interfaceStateStore={interfaceStateStore} theme={theme} themeProperties={themeProperties} displayMode={displayMode} serverSynchronization={serverSynchronization}></TestimonialRow> }
			{ showPro && <ContactRow bioStore={bioStore} interfaceStateStore={interfaceStateStore} theme={theme} themeProperties={themeProperties} displayMode={displayMode} serverSynchronization={serverSynchronization}></ContactRow> }
			<Footer bioStore={bioStore} interfaceStateStore={interfaceStateStore} displayMode={displayMode}></Footer>
			<VideoDisplayPopup bioStore={bioStore} interfaceStateStore={interfaceStateStore} theme={theme} themeProperties={themeProperties} displayMode={displayMode} serverSynchronization={serverSynchronization}></VideoDisplayPopup>
			<QuestionPopup interfaceStateStore={interfaceStateStore} serverSynchronization={serverSynchronization}></QuestionPopup>
			{editPanels}
			<VideoPromoPopup interfaceStateStore={interfaceStateStore} serverSynchronization={serverSynchronization}></VideoPromoPopup>
			<ShowingUploadPopup interfaceStateStore={interfaceStateStore} serverSynchronization={serverSynchronization}></ShowingUploadPopup>
		</div>
	);
});

export default Bio;
