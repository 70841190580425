import React from 'react';
import { observer } from "mobx-react-lite";
import EditPanel from './EditPanel';
import { Input, Checkbox } from 'rsuite';
import PhotoEditField from './PhotoEditField';
import cropPhoto from './PhotoCropping';

const ContactDetailsEditPanel = observer(({ bioStore, interfaceStateStore, serverSynchronization }) => {
	function nameChange(value) {
		bioStore.setContactName(value);
	}
	function urlChange(value) {
		bioStore.setContactURL(value);
	}
	function emailAddressChange(value) {
		bioStore.setContactEmailAddress(value);
	}
	function phoneNumberChange(value) {
		bioStore.setContactPhoneNumber(value);
	}
	function smsNumberChange(value) {
		bioStore.setContactSMSNumber(value);
	}
	function showVCardChange(value, checked) {
		bioStore.setShowVCard(checked);
	}
	function uploadPhoto() {
		cropPhoto(bioStore, interfaceStateStore, serverSynchronization, 'contact-accent-photo').then(() => interfaceStateStore.setCurrentlyEditing(null, null, null));
	}
	function deleteContent() {
		bioStore.setContactName("");
		bioStore.setContactURL("");
		bioStore.setContactEmailAddress("");
		bioStore.setContactPhoneNumber("");
		bioStore.setContactSMSNumber("");
		bioStore.updatePhoto("contact-accent-photo", { customization: "noPhoto", cropX: 0, cropY: 0, cropWidth: 0, cropHeight: 0 });
		bioStore.setCompanyName("");
		bioStore.setJobTitle("");
		bioStore.setAddress1("");
		bioStore.setAddress2("");
		bioStore.setCity("");
		bioStore.setRegion("");
		bioStore.setPostalCode("");
		bioStore.setCountry("");
		bioStore.setAdditionalVCardNotes("");
		interfaceStateStore.setCurrentlyEditing(null, null, null);
	}
	
	let vCardProperties = null;
	if(bioStore.showVCard) {
		vCardProperties = (
			<div>
				<div className="edit-panel-group-label">The information below is not displayed on your live link, but can be included in your contact information when visitors add it to their phone Contacts.</div>
				<div className="edit-panel-field">
					<label>Company name:</label>
					<Input value={bioStore.companyName} placeholder="If applicable" onChange={ (value) => bioStore.setCompanyName(value) } />
				</div>
				<div className="edit-panel-field">
					<label>Additional information/notes:</label>
					<Input value={bioStore.additionalVCardNotes} placeholder="Leave an inspiring message so they'll remember you" onChange={ (value) => bioStore.setAdditionalVCardNotes(value) } />
				</div>
			</div>
		);
	}
		
	return (
		<EditPanel
			bioStore={bioStore}
			interfaceStateStore={interfaceStateStore}
			itemType="contact-details"
			title="Virtual Biz Card"
			helpText={{
				'bio': 'Add your contact information to make it simple for visitors to connect with you. Only the information you enter will be displayed.',
				'quickclick': 'Make it simple for guests to connect with you by entering your contact information below. Only the information you enter will be displayed.',
				'quiz': 'Make it simple for visitors to connect with you by entering your contact information below. Only the information you enter will be displayed.',
				'informational': 'Add your contact information to make it simple for visitors to connect with you. Only the information you enter will be displayed. '
			}}
			onDone={uploadPhoto}
			showDelete={true}
			onDelete={deleteContent}
			deleteLabel="Clear this section"
			showTipsButton={true}
		>
			<div className="edit-panel-field">
				<Checkbox checked={bioStore.showVCard} onChange={showVCardChange}>Allow mobile visitors to easily add your information to their phone Contacts</Checkbox>
			</div>
			<PhotoEditField bioStore={bioStore} interfaceStateStore={interfaceStateStore} serverSynchronization={serverSynchronization} label="Photo:" slot="contact-accent-photo"></PhotoEditField>
			<div className="edit-panel-field">
				<label>Name:</label>
				<Input value={bioStore.contactName || ""} onChange={nameChange} />
			</div>
			<div className="edit-panel-field">
				<label>Website:</label>
				<Input value={bioStore.contactURL || ""} onChange={urlChange} />
			</div>
			<div className="edit-panel-field">
				<label>Email address:</label>
				<Input value={bioStore.contactEmailAddress || ""} onChange={emailAddressChange} />
			</div>
			<div className="edit-panel-field">
				<label>Phone number:</label>
				<Input value={bioStore.contactPhoneNumber || ""} onChange={phoneNumberChange} />
			</div>
			<div className="edit-panel-field">
				<label>Mobile Number to receive texts:</label>
				<Input value={bioStore.contactSMSNumber || ""} onChange={smsNumberChange} />
			</div>
			{ vCardProperties }
		</EditPanel>
	);
});
 
export default ContactDetailsEditPanel;
