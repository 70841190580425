import React from 'react';
import IntroText from './IntroText';
import IntroPhoto from './IntroPhoto';
import { observer } from "mobx-react-lite";

const IntroRow = observer(({ bioStore, interfaceStateStore, theme, themeProperties, displayMode, serverSynchronization}) => {
	return (
		<div className="intro-row">
			<div className="intro-photo-column">
				<IntroPhoto bioStore={bioStore} interfaceStateStore={interfaceStateStore} theme={theme} themeProperties={themeProperties} displayMode={displayMode} serverSynchronization={serverSynchronization}></IntroPhoto>
			</div>
			<div className="intro-text-column">
				<IntroText bioStore={bioStore} interfaceStateStore={interfaceStateStore} theme={theme} themeProperties={themeProperties} displayMode={displayMode} serverSynchronization={serverSynchronization}></IntroText>
			</div>
		</div>
	);
});
 
export default IntroRow;