export default async function copy(interfaceStateStore) {
	if(navigator.clipboard && navigator.clipboard.writeText) {
		const promise = navigator.clipboard.writeText(interfaceStateStore.getURL());
		if(promise) {
			promise.then(() => {
				interfaceStateStore.setURLCopied(true);
			}, () => {
				copyFailed();
			});
			
			return;
		}
	}
		
	// If we made it this far, the copy operation failed
	copyFailed();	
}

function copyFailed() {
	alert("We're sorry, we could not automatically copy your link to the clipboard. Please manually copy your link.");
}
  