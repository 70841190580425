import React from 'react';
import { observer } from "mobx-react-lite";
import EditButton from './EditButton';
import transparentPixel from './images/transparent-pixel.png';

const Photo = observer(({ bioStore, interfaceStateStore, displayMode, serverSynchronization, slot, urlForViewing, urlForEditing, urlForPreviewing, classes, imageClasses, editorItemType, alt, children, editLabel, addLabel, isAdd, noTransparentSubstitute }) => {
	let editButton = null;
	if(editorItemType && displayMode === "editing") {
		editButton = (
			<EditButton
				addLabel={addLabel}
				editLabel={editLabel}
				isAdd={isAdd}
				onClick={() => interfaceStateStore.setCurrentlyEditing(editorItemType, editorItemType || null, null)}>
			</EditButton>
		);
	}
	
	let photoURL = null;
	let photo = bioStore.photos.get(slot);
	if(photo.customization === "providedCustom") {
		const useCropped = (interfaceStateStore.currentlyEditingPhotoSlot !== slot);
		const extension = useCropped ? "jpg" : photo.uncroppedExtension;
		const cropString = useCropped ? "-cropped" : "-uncropped";
		photoURL = serverSynchronization.getMediaURL(slot, cropString, extension, photo.revisionNumber);
	} else if(photo.customization === "noPhoto") {
		if(!noTransparentSubstitute) photoURL = transparentPixel;
	} else {
		switch(displayMode) {
			case "viewing":
				photoURL = urlForViewing;
				break;
			case "editing":	
				photoURL = urlForEditing;
				break;
			case "previewing":
				photoURL = urlForPreviewing;
				break;
			default:
				break;
		}
	}

	if(!photoURL && slot !== "intro-photo") { // Intro photo is an exception, we want to display its frame/controls even if there's no photo
		return null;	
	} else {
		return (
			<div className={classes}>
				{editButton}
				{ photoURL && <img src={photoURL} alt={alt} className={imageClasses}></img> }
				{children}
			</div>
		);
	}
});
 
export default Photo;