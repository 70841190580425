import React from 'react';
import { observer } from "mobx-react-lite";
import Photo from './Photo';
import VideoPlayButton from './VideoPlayButton';

const IntroPhoto = observer(({ bioStore, interfaceStateStore, theme, themeProperties, displayMode, serverSynchronization }) => {
	const defaultURL = themeProperties.introPhoto;
	const isAdd = bioStore.photos.get("intro-photo").customization !== "providedCustom";
	return (
		<React.Fragment>
			<Photo bioStore={bioStore} interfaceStateStore={interfaceStateStore} displayMode={displayMode} serverSynchronization={serverSynchronization} slot="intro-photo" urlForEditing={defaultURL} urlForPreviewing={defaultURL} classes="intro-photo" imageClasses="intro-photo-image" editorItemType="intro-photo" alt="Profile photo" addLabel="Add Profile Pic" isAdd={isAdd}>
				<VideoPlayButton bioStore={bioStore} interfaceStateStore={interfaceStateStore} theme={theme} themeProperties={themeProperties} displayMode={displayMode} serverSynchronization={serverSynchronization}></VideoPlayButton>
			</Photo>
		</React.Fragment>
	);
});
 
export default IntroPhoto;