import React from 'react';
import { observer } from "mobx-react-lite";
import EditButton from './EditButton';
import {contentForDisplayMode} from './Stores';
import {cleanUserProvidedLink} from './URLCleanupUtil';
import { isMobile } from './BrowserUtil';

const ContactDetails = observer(({bioStore, interfaceStateStore, theme, themeProperties, displayMode, serverSynchronization}) => {
	const contactName = contentForDisplayMode(displayMode, bioStore.contactName, "Your Name");
	const contactURL = contentForDisplayMode(displayMode, bioStore.contactURL, "https://example.com");
	const contactEmailAddress = contentForDisplayMode(displayMode, bioStore.contactEmailAddress, "contact@example.com");
	const contactPhoneNumber = contentForDisplayMode(displayMode, bioStore.contactPhoneNumber, "800-555-1234");
	const contactSMSNumber = contentForDisplayMode(displayMode, bioStore.contactSMSNumber, "800-555-1234");

	const hasContent = (contactName || contactURL || contactEmailAddress || contactPhoneNumber || contactSMSNumber);
	let editButton = null;
	if(displayMode === "editing") {
		editButton = (
			<EditButton
				onClick={() => interfaceStateStore.setCurrentlyEditing("contact-details", null, "contact-accent-photo")}
				isAdd={!hasContent}
				addLabel="Add Contact Info"
			>
			</EditButton>
		);
	}
	
	function liForURL(url, iconClass, logActionType) {
		return (
			<li>
				<a href={url} onClick={() => serverSynchronization.logAction(logActionType) }>
					<i className={iconClass}></i>
				</a>
			</li>
		);
	}

	
	if(hasContent) {
		let vCard = null; 
		if(bioStore.showVCard && isMobile()) {
			vCard = (
				<div className="contact-vcard">
					<button
						className="contact-vcard-open-button"
						onClick={() => {
							serverSynchronization.logAction("click-vcard");
							serverSynchronization.getVCard();
						}}>Add to Phone Contacts</button>
				</div>
			);
		}
		return (
			<React.Fragment>
				<h3 className="contact-name">{contactName}{editButton}</h3>
				<ul className="contact-list">
					<li className="contact-web-site">
						<a
							href={cleanUserProvidedLink(contactURL)}
							onClick={() => serverSynchronization.logAction("click-contact-web-site")}
							target="_blank"
							rel="noreferrer"
						>
							{contactURL}
						</a>
					</li>
				</ul>
				<ul className="contact-click-to-contact">
					{ contactEmailAddress && liForURL(cleanUserProvidedLink(contactEmailAddress), "far fa-envelope-open", "click-contact-email-address") }
					{ contactPhoneNumber && liForURL(cleanUserProvidedLink(contactPhoneNumber, false), "fas fa-phone", "click-contact-phone-number") }
					{ contactSMSNumber && liForURL(cleanUserProvidedLink(contactSMSNumber, true), "far fa-comment-dots", "click-contact-sms-number") }
				</ul>
				{ vCard }
			</React.Fragment>
		);
	} else {
		return editButton;
	}
});
 
export default ContactDetails;