import React from 'react';
import { observer } from "mobx-react-lite";
import EditPanel from './EditPanel';
import VideoPlayer from './VideoPlayer';
import HelpButton from './HelpButton';

const MaxFileLength = 20000000;

const VideoEditPanel = observer(({ bioStore, interfaceStateStore, serverSynchronization }) => {
	function handleUpload(e) {
		const input = e.target;
		if(input.files && input.files.length > 0) {
			const file = input.files[0];
			if(file.size && file.size > MaxFileLength) {
				alert("Oops! Looks like your video exceeded the 10-second limit. The best intro is short and sweet.")
			} else {
				serverSynchronization.uploadVideo(file);
			}
		}
	}
	
	function handleDelete(evt) {
		bioStore.setVideo(false, bioStore.videoRevisionNumber);
	}
	
	return (
		<EditPanel
			bioStore={bioStore}
			interfaceStateStore={interfaceStateStore}
			itemType="video"
			title="IntroClip Video"
			helpText={{
				'bio': 'Upload a video that allows people to connect with you quickly.',
				'quickclick': 'Upload a video to greet your guests and explain who the event is for, why they can benefit and how to get started.',
				'quiz': 'Upload a video to greet your visitors and tell them what to expect as they go through your quiz.',
				'informational': 'Upload a video that allows people to connect with you quickly.'
			}}
			proTipText="Suggested video length is 10-seconds to capture visitor's attention."
			className="video-edit-panel"
			showTipsButton={true}
		>
			<div className="edit-panel-field">
				<div className="input-with-side-button">
					<input type="file" onChange={handleUpload} accept=".mpg, .mpeg, .mp4, .mov" />
					<HelpButton>
						<p>Video Requirements:</p>
						<ol>
							<li>File format is .mpg, .mpeg, mp4, or .mov</li>
							<li>File size is under 19mb</li>
						</ol>
						<p>*See <a href="/help-center" target="_blank">Help Center</a> for questions.</p>
					</HelpButton>
				</div>
				{/* <ProTip text="Suggested Video Length is 10-seconds to capture visitors attention."></ProTip> */}
				{ bioStore.hasVideo && <VideoPlayer url={serverSynchronization.getMediaURL("video", "", "mp4", bioStore.videoRevisionNumber)} className="video-preview" playsinline="true"></VideoPlayer> }
				{ bioStore.hasVideo && <div className="edit-panel-file-delete-field"><button onClick={handleDelete}>Delete video</button></div> }
			</div>
		</EditPanel>
	);
});
 
export default VideoEditPanel;
 