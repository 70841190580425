// Match bracketed text between 5-50 characters (limiting the range to cut down on false positives from user-entered text)
function containsTemplateReplacementBracketedString(str) {
	if(!str) return false;
	// Check for an opening bracket, followed by 5-50 non-bracket characters, followed by a closing bracket
	return /{[^{}]{5,50}}/g.test(str);
}
function urlContainsTemplateReplacementBracketedString(url) {
	if(typeof(url) === "string") {
		return containsTemplateReplacementBracketedString(url);
	} else {
		if(!url) return false;
		switch(url.type) {
			case "question":
				// Check the question
				if(containsTemplateReplacementBracketedString(url.questionText)) return true;
				
				// Check the answers
				return url.answers.filter(answer => containsTemplateReplacementBracketedString(answer)).length > 0;
			case "upload":
				return false; // Uploads can't contain brackets
			default:
				return null;
		}
	}
}

export { containsTemplateReplacementBracketedString, urlContainsTemplateReplacementBracketedString };