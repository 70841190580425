

export default function mailtoForGoal(primaryGoal, url, referralURL) {
	return `mailto:?subject=${encodeURIComponent("Look what I just created!")}&body=${encodeURIComponent(getBody(primaryGoal, url, referralURL))}`
}

function getBody(primaryGoal, url, referralURL) {
	switch(primaryGoal) {
		case 'bio':
			return `Check it out: ${url}

I just created my very own custom link! It's a modern way to share my personality and what I sell online; all in one place. I'm so excited for you to see it first!

Bonus: as a brand new user, I get a discount code to share for 48 hours only with 3 friends, so I immediately thought of you.

If you want to customize one for yourself, use code SAVE5NOW and use my referral link below to get started.

My Referral Link: ${referralURL}

P.S. I'd love your feedback, so let me know whatcha think once you check it out!
`
		case 'quickclick':
			return `Check it out: ${url}

I just created my very own custom link! It's a new, modern way to host virtual events making it super easy for my guests to skim, scroll and save! I'm so excited for you to see it first!

Bonus: as a brand new user, I get a discount code to share for 48 hours only with 3 friends, so I immediately thought of you.

If you want to customize one for yourself, use code SAVE5NOW and use my referral link below to get started.

My Referral Link: ${referralURL}

P.S. I'd love your feedback, so let me know whatcha think once you check it out!					
`
		case 'quiz':
			return `Check it out: ${url}

I just created my very own custom link! It's a new, modern way to interact and quiz my followers so that I can get to know them better. I'm so excited for you to see it first!

Bonus: as a brand new user, I get a discount code to share for 48 hours only with 3 friends, so I immediately thought of you.

If you want to customize one for yourself, use code SAVE5NOW and use my referral link below to get started.

My Referral Link: ${referralURL}

P.S. I'd love your feedback, so let me know whatcha think once you check it out!
`
		default:
			return `
Check it out: ${url}

I just created my very own custom link! It's a modern way to share anything and I'm so excited for you to see it first!

Bonus: as a brand new user, I get a discount code to share for 48 hours only with 3 friends, so I immediately thought of you.

If you want to customize one for yourself, use code SAVE5NOW and use my referral link below to get started.

My Referral Link: ${referralURL}

P.S. I'd love your feedback, so let me know whatcha think once you check it out!
`
	}
}