import React from 'react';
import { observer } from "mobx-react-lite";
import EditPanel from './EditPanel';
import PhotoEditField from './PhotoEditField';
import cropPhoto from './PhotoCropping';

const IntroPhotoEditPanel = observer(({ bioStore, interfaceStateStore, serverSynchronization }) => {
	function uploadPhoto() {
		cropPhoto(bioStore, interfaceStateStore, serverSynchronization, 'intro-photo').then(() => interfaceStateStore.setCurrentlyEditing(null, null, null));
	}
	
	return (
		<EditPanel
			bioStore={bioStore}
			interfaceStateStore={interfaceStateStore}
			itemType="intro-photo"
			title="Profile Picture"
			helpText={{
				'bio': 'Upload an image that represents your goals or theme for your link.',
				'quickclick': 'Upload an image that represents your goals or theme for this event.',
				'quiz': 'Upload an image that represents your goals or theme for this quiz.',
				'informational': 'Upload your favorite photo!'
			}}
			onDone={uploadPhoto}
			showTipsButton={true}
		>
			<PhotoEditField bioStore={bioStore} interfaceStateStore={interfaceStateStore} serverSynchronization={serverSynchronization} slot="intro-photo"></PhotoEditField>
		</EditPanel>
	);
});
 
export default IntroPhotoEditPanel;
