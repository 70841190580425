import React from 'react';
import { observer } from "mobx-react-lite";
import EditButton from './EditButton';
import Photo from './Photo';
import {hrefForLinkURL, onClickForLinkURL, targetForLinkURL} from './URLDisplayUtil';

// id is only needed if an edit button is required
const CarouselCard = observer(({ bioStore, interfaceStateStore, serverSynchronization, id, defaultPhotoURL, heading, body, buttonLabel, buttonURL, treatPhoneNumberAsSMS, theme, themeProperties, displayMode, photoSlot }) => {
	let editButton = null;
	if(id && displayMode === "editing") {
		editButton = (
			<EditButton
				onClick={() => interfaceStateStore.setCurrentlyEditing("carousel-card", id, null)}
			>
			</EditButton>
		);
	} 
	if(!buttonURL) buttonURL = "#";
	const buttonIconElement = themeProperties.carouselCardButtonIcon && <img src={themeProperties.carouselCardButtonIcon} alt=""></img>;
	
	return (
		<div className="carousel-card">
			<div className="card-photo-column">
				<Photo bioStore={bioStore} interfaceStateStore={interfaceStateStore} displayMode={displayMode} serverSynchronization={serverSynchronization} slot={photoSlot} urlForEditing={defaultPhotoURL} urlForPreviewing={defaultPhotoURL} noTransparentSubstitute={true}></Photo>	
			</div>
			<div className="card-contents-column">
				<h2 className="card-heading">{heading}</h2>
				<div className="card-body">{body}</div>
				<a
					href={hrefForLinkURL(buttonURL, treatPhoneNumberAsSMS, interfaceStateStore, serverSynchronization)}
					target={targetForLinkURL(buttonURL)}
					onClick={onClickForLinkURL(buttonURL, interfaceStateStore, serverSynchronization, "click-card", buttonLabel)}
					className="card-button"
					rel="noreferrer"
				>
					{buttonLabel}
					{buttonIconElement}
				</a>
				{editButton}
			</div>
		</div>
	);
});
 
export default CarouselCard;