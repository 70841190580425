import React from 'react';
import { observer } from "mobx-react-lite";
import Link from './Link';
import EditButton from './EditButton';
import {contentForDisplayMode} from './Stores';

const defaultLinks = [ 
	{ label: "Join My Newsletter", url: "#" },
	{ label: "Take My Quiz", url: { type: 'question', questionText: 'What is your favorite color?', answers: ['Red', 'Green', 'Blue'], requireEmail: true } },
	{ label: "Read the Blog", url: "#" },
	{ label: "Download the Guide", url: "#" },
	{ label: "Listen to the Podcast", url: "#" }
 ];
 
const LinksRow = observer(({ bioStore, interfaceStateStore, theme, themeProperties, displayMode, serverSynchronization }) => {
	function createLinkElement(link, index) {
		const key = link.id || index;
		return <Link key={key} bioStore={bioStore} interfaceStateStore={interfaceStateStore} id={link.id} label={link.label} url={link.url} treatPhoneNumberAsSMS={link.treatPhoneNumberAsSMS} theme={theme} themeProperties={themeProperties} displayMode={displayMode} serverSynchronization={serverSynchronization}></Link>
	}
	
	// If bioStore.links is an empty array instead of null, it will be used, which is what we want: the user has edited links and "selected" zero
	let links = contentForDisplayMode(displayMode, bioStore.links, defaultLinks);
	if(links === null) links = [];
	const linkElements = links.map(createLinkElement);
	let addLinkElementRow = null;
	if(displayMode === "editing") {
		addLinkElementRow = (
			<div className="links-link-list-row">
				<EditButton
					addLabel="Add Button"
					isAdd={true}
					onClick={() => {
						try {
							const newLinkID = bioStore.addLink("", "", false);
							interfaceStateStore.setCurrentlyEditing("link", newLinkID, null);
						} catch(ex) {
							console.log("Error adding link: " + ex.message);	
						}
					}}>
				</EditButton>
			</div>
		);
	}
	return (
		<div className="links-row">
			<div className="links-column">
				<div className="links-link-list-row">{linkElements}</div>
				{addLinkElementRow}
			</div>
		</div>
	);
});
 
export default LinksRow;