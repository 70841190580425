import React from 'react';
import { observer } from "mobx-react-lite";
import VideoPlayer from './VideoPlayer';

const ShowingUploadPopup = observer(({ interfaceStateStore, serverSynchronization }) => {
	function dismissOverlay() {
		interfaceStateStore.setShowingUpload(null, null);	
	}
	if(!interfaceStateStore.showingUploadID) {
		return null;
	} else {
		const src = serverSynchronization.getUploadDisplayURL(interfaceStateStore.showingUploadID, interfaceStateStore.showingUploadFileType);
		if(interfaceStateStore.showingUploadFileType === "mp4") {
			return (
				<div className="video-display-popup" onClick={dismissOverlay}>
					<div className="video-row">
						<VideoPlayer url={src} autoPlay="true" playsinline="true"></VideoPlayer>
					</div>
					<i className="fas fa-times-circle video-display-close-button" onClick={dismissOverlay}></i>
				</div>
			);
		} else {
			return (
				<div className="image-display-popup" onClick={dismissOverlay}>
					<img src={src} alt=""></img>
					<i className="fas fa-times-circle video-display-close-button" onClick={dismissOverlay}></i>
				</div>
			);
		}
	}
});
 
export default ShowingUploadPopup;