import React from 'react';
import { observer } from "mobx-react-lite";
import EditButton from './EditButton';
import Photo from './Photo';
import {contentForDisplayMode} from './Stores';

const TestimonialRow = observer(({bioStore, interfaceStateStore, theme, themeProperties, displayMode, serverSynchronization}) => {
	const providedPhoto = (bioStore.photos.get("testimonial-photo").customization === "providedCustom");
	const heading = contentForDisplayMode(displayMode, bioStore.testimonialHeading, "Kind Words");
	const body = contentForDisplayMode(displayMode, bioStore.testimonialBody, "This is the perfect spot to include one of your favorite quotes or testimonial from a happy client.");
	const attribution = contentForDisplayMode(displayMode, bioStore.testimonialAttribution, "A Happy Customer");
	const hasContent = (providedPhoto || heading || body || attribution)
	let editButton = null;
	if(displayMode === "editing") {
		editButton = (
			<EditButton
				addLabel="Add Social Proof"
				isAdd={!hasContent}
				onClick={() => interfaceStateStore.setCurrentlyEditing("testimonial", null, "testimonial-photo")}
			>
			</EditButton>
		);
	}
	
	if(hasContent) {
		return (
			<div className="testimonial-row">
				<div className="testimonial-photo-column">
					<Photo bioStore={bioStore} interfaceStateStore={interfaceStateStore} displayMode={displayMode} serverSynchronization={serverSynchronization} slot="testimonial-photo" urlForPreviewing={themeProperties.testimonialPhoto} imageClasses="testimonial-photo" alt="Testimonial photo" classes="testimonial-photo-wrapper">
						{ themeProperties.testimonialPhotoDecoration && <div className="testimonial-photo-decoration"><img src={themeProperties.testimonialPhotoDecoration} className="testimonial-photo-decoration-image" alt=""></img></div> }
					</Photo>
				</div>
				<div className="testimonial-contents-column">
					<div className="testimonial-contents">
						<h2 className="testimonial-heading">{heading}</h2>
						<div className="testimonial-body">{body}</div>
						<div className="testimonial-attribution">{attribution}</div>
						{editButton}
					</div>
				</div>
			</div>
		);
	} else {
		return editButton;
	}
});
 
export default TestimonialRow;