import React from 'react';
import { observer } from "mobx-react-lite";
import videoPlayButton from './images/video-play-button.png';
import EditButton from './EditButton';

const VideoPlayButton = observer(({ bioStore, interfaceStateStore, theme, themeProperties, displayMode, serverSynchronization }) => {
	function buttonClick() {
		if(interfaceStateStore.hasPaidPlan && bioStore.hasVideo) {
			interfaceStateStore.setViewingVideo(true);	
			serverSynchronization.logAction("click-video-play");
		} else if(interfaceStateStore.hasPaidPlan && displayMode !== "previewing") { // If we're previewing, we want to take the alternate path and show the promo
			interfaceStateStore.setCurrentlyEditing("video", null, null);
		} else {
			interfaceStateStore.setShowingVideoPromoPopup(true);
		}
	}
	
	// In editing or previewing modes, the button shows up no matter what. In viewing mode, it only shows up if there's video.
	let show = false;
	switch(displayMode) {
		case "editing":
		case "previewing":
			show = true;
			break;
		case "viewing":
			show = bioStore.hasVideo && interfaceStateStore.hasPaidPlan;
			break;
		default:
			break;
	}

	let unopenedPrompter = false;
	if(displayMode === "viewing") {
		if(!interfaceStateStore.viewedVideo) unopenedPrompter = true;
	}

	let imageClasses = "video-play-button-image";
	if(unopenedPrompter) imageClasses += " unopened-prompter";
	
	if(!show) {
		return null;
	} else {
		let editButton = null;
		if(displayMode === "editing" && interfaceStateStore.hasPaidPlan) {
			editButton = (
				<EditButton
					addLabel="Add IntroClip Video"
					isAdd={!bioStore.hasVideo}
					onClick={() => interfaceStateStore.setCurrentlyEditing("video", null, null)}>
				</EditButton>
			);
		}
		return (
			<>
				<div className="video-play-button">
					{editButton}
					<img src={videoPlayButton} className={imageClasses} alt="Play button" onClick={buttonClick}></img>
				</div>
			</>
		);
	}
});
 
export default VideoPlayButton;