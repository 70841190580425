import React from 'react';
import { observer } from "mobx-react-lite";
import EditButton from './EditButton';
import {hrefForLinkURL, onClickForLinkURL, targetForLinkURL} from './URLDisplayUtil';

// id is only needed if an edit button is required
const Link = observer(({ bioStore, interfaceStateStore, id, url, treatPhoneNumberAsSMS, label, theme, themeProperties, displayMode, serverSynchronization }) => {
	const linkIcon = themeProperties.linkIcon;
	const iconElement = linkIcon && <img src={linkIcon} className="link-icon" alt=""></img>;

	let editButton = null;
	if(id && displayMode === "editing") {
		editButton = (
			<EditButton
				onClick={()  => interfaceStateStore.setCurrentlyEditing("link", id, null)}
			>
			</EditButton>
		);
	};
	
	let linkUnderlineImage = null;
	if(themeProperties.linkUnderlineImage) linkUnderlineImage = <img src={themeProperties.linkUnderlineImage} className="link-underline-image" alt=""></img>
	
	return (
		<>
			<a
				className="link"
				href={hrefForLinkURL(url, treatPhoneNumberAsSMS, interfaceStateStore, serverSynchronization )}
				target={targetForLinkURL(url)}
				onClick={onClickForLinkURL(url, interfaceStateStore, serverSynchronization, "click-link", label)}
				rel="noreferrer"
				data-front={label}
				data-back={label}
			>
				{themeProperties.usesFlipButtons ? "" : label}
				{iconElement}
				{editButton}
			</a>
			{linkUnderlineImage}
		</>
	);
});
 
export default Link;