import React from 'react';
import { observer } from "mobx-react-lite";
import EditPanel from './EditPanel';
import { Input } from 'rsuite';
import PhotoEditField from './PhotoEditField';
import cropPhoto from './PhotoCropping';
import TreatPhoneNumberAsSMSPrompt from './TreatPhoneNumberAsSMSPrompt';
import { isConsideredPhoneNumber } from './URLCleanupUtil';
import { linkURLTypeString } from './Stores';
import { cardIndexForPhotoSlot } from './Stores';
import LinkURLQuestionEditFields from './LinkURLQuestionEditFields';
import HelpButton from './HelpButton';
import LinkTypeDropdown from './LinkTypeDropdown';
import LinkURLUploadEditFields from './LinkURLUploadEditFields';

const CarouselCardEditPanel = observer(({ bioStore, interfaceStateStore, serverSynchronization }) => {
	function linkURLTypeChange(value) {
		bioStore.changeCarouselCardButtonURLType(id, value);
	}
	function headingChange(value) {
		bioStore.setCarouselCardHeading(id, value);
	}
	function bodyChange(value) {
		bioStore.setCarouselCardBody(id, value);
	}
	function buttonLabelChange(value) {
		bioStore.setCarouselCardButtonLabel(id, value);
	}
	function buttonURLChange(value) {
		bioStore.setCarouselCardButtonURL(id, value);
	}
	function treatPhoneNumberAsSMSChange(value) {
		bioStore.setCarouselCardTreatPhoneNumberAsSMS(id, value);	
	}
	function deleteCard() {
		if(card) {
			serverSynchronization.deleteCard(index, card);
		}
	}
	function indexChange(evt) {
		const newIndex = parseInt(evt.target.value, 10);
		serverSynchronization.swapCards(index, newIndex);
	}
	function createURLControls(buttonURL, treatPhoneNumberAsSMS) {
		return (
			<div className="edit-panel-field">
				<label>Button Link:</label>
				<div className="input-with-side-button">
					<Input value={buttonURL || ""} onChange={buttonURLChange} />
					<HelpButton>
						<p>Link to anything! As long as it has a URL you can link to it! Websites, Canva links, Venmo, Youtube, Google Docs, Google Share, Google Forms, Email lists, Sign up forms, PDFs, Email address, AND a phone number to text or call.</p>
					</HelpButton>
				</div>
				{ isConsideredPhoneNumber(buttonURL) && <TreatPhoneNumberAsSMSPrompt value={treatPhoneNumberAsSMS} onChange={treatPhoneNumberAsSMSChange}></TreatPhoneNumberAsSMSPrompt> }
			</div>	
		);
	}
	 
	const id = interfaceStateStore.currentlyEditingItemID;	
	const card = id ? bioStore.getCarouselCardByID(id) : null;
	const photoSlot = bioStore.getPhotoSlotForCarouselCardID(id);
	const heading = card ? card.heading : "";
	const body = card ? card.body : "";
	const buttonLabel = card ? card.buttonLabel : "";
	const buttonURL = card ? card.buttonURL : "";
	const urlTypeString = linkURLTypeString(buttonURL);
	const treatPhoneNumberAsSMS = card ? card.treatPhoneNumberAsSMS : false;
	let index = 0;
	if(photoSlot) {
		index = cardIndexForPhotoSlot(photoSlot);
	}

	function uploadPhoto() {
		if(photoSlot) {
			cropPhoto(bioStore, interfaceStateStore, serverSynchronization, photoSlot).then(() => interfaceStateStore.setCurrentlyEditing(null, null, null));
		}
	}
	
	let cardCount = 0;
	if( bioStore.carouselCards ) cardCount = bioStore.carouselCards.length; 
	
	const indexChangeOptions = [<option value="0">1st/Catchy Offer</option>];
	if(cardCount > 1) indexChangeOptions.push(<option value="1">2nd/Middle</option>);
	if(cardCount > 2) indexChangeOptions.push(<option value="2">3rd/Last</option>);
	
	const indexChangeInterface = (
		<div className="edit-panel-field">
			<label>
				Position:
			</label>
			<select value={index} onChange={indexChange}>
				{indexChangeOptions}
			</select>
		</div>	
	);
	
	let panelTitle = "Carousel";
	let helpText = null;
	let proTipText = null;
	switch(index) {
		case 0:
			panelTitle = "1st Carousel ";
			helpText = {
				'bio': 'Add an image, heading and content that grabs your visitor\'s attention.',
				'quickclick': 'Add an image, heading and content that grabs your guest\'s attention.',
				'quiz': 'Add an image, heading and content that grabs your visitor\'s attention.',
				'informational': 'Add an image, heading and content that grabs your visitor\'s attention.'
			};
			proTipText = {
				'bio': 'Add an eye-catching offer in this first position to attract more clicks.',
				'quickclick': 'Stop your guests scroll with your best offer. CREATE URGENCY. This is the show-stopper.',
				'quiz': 'Use this section to add more information for your visitors learn more about what you are sharing.',
				'informational': 'Add an eye-catching offer in this first position to attract more clicks.'
			};
			break;
		case 1:
			panelTitle = "2nd Carousel";
			helpText = {
				'bio': 'Add an image, heading and content that grabs your visitor\'s attention.',
				'quickclick': 'Add an image, heading and content that grabs your guest\'s attention.',
				'quiz': 'Add an image, heading and content that grabs your visitor\'s attention.',
				'informational': 'Add an image, heading and content that grabs your visitor\'s attention.'
			};
			proTipText = {
				'bio': 'Mix it up with business and pleasure here! Include at least one of your personal areas of interest.',
				'quickclick': 'Sweeten the deal with a limited-time gift with purchase, before/after pictures, or something fun and exciting that makes them want to click to learn more.',
				'quiz': 'Mix it up with business and pleasure here! Include at least one of your personal areas of interest.',
				'informational': null
			};
			break;
		case 2:
			panelTitle = "3rd Carousel";
			helpText = {
				'bio': 'Add an image, heading and content that grabs your visitor\'s attention.',
				'quickclick': 'Add an image, heading and content that grabs your guest\'s attention.',
				'quiz': 'Add an image, heading and content that grabs your visitor\'s attention.',
				'informational': 'Add an image, heading and content that grabs your visitor\'s attention.'
			};
			proTipText = {
				'bio': 'Mix it up with business and pleasure here! Include at least one of your personal areas of interest.',
				'quickclick': null,
				'quiz': null,
				'informational': null
			};
			break;
		default:
			panelTitle = "Carousel";
			proTipText = null;
			helpText = null;
	}
	
	let linkURLSettings = null;
	switch(urlTypeString) {
		case "url":
			linkURLSettings = createURLControls(buttonURL, treatPhoneNumberAsSMS);
			break;
		case "question":
			linkURLSettings = <LinkURLQuestionEditFields linkURL={buttonURL} hasPaidPlan={interfaceStateStore.hasPaidPlan}></LinkURLQuestionEditFields>;
			break;
		default:
			linkURLSettings = <LinkURLUploadEditFields linkURL={buttonURL} hasPaidPlan={interfaceStateStore.hasPaidPlan} serverSynchronization={serverSynchronization}></LinkURLUploadEditFields>;
			break;
	}
	
	return (
		<EditPanel
			bioStore={bioStore}
			interfaceStateStore={interfaceStateStore}
			itemType="carousel-card"
			title={panelTitle}
			showDelete={true}
			deleteLabel="Delete"
			onDelete={deleteCard}
			onDone={uploadPhoto}
			helpText={helpText}
			proTipText={proTipText}
			additionalMetaControls={indexChangeInterface}
			showTipsButton={true}
		>
			{ photoSlot && <PhotoEditField bioStore={bioStore} interfaceStateStore={interfaceStateStore} serverSynchronization={serverSynchronization} label="Photo:" slot={photoSlot} ></PhotoEditField> }
			<div className="edit-panel-field">
				<label>Heading:</label>
				<Input value={heading || ""} onChange={headingChange} />
			</div>
			<div className="edit-panel-field">
				<label>Body:</label>
				<Input value={body || ""} componentClass="textarea" rows={3} onChange={bodyChange} />
			</div>
			<div className="edit-panel-field">
				<label>Button Title:</label>
				<Input value={buttonLabel || ""} onChange={buttonLabelChange} />
			</div>
			<LinkTypeDropdown url={buttonURL} onChange={linkURLTypeChange}></LinkTypeDropdown>
			{ linkURLSettings }
		</EditPanel>
	);
});
 
export default CarouselCardEditPanel;
