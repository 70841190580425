import React from 'react';
import { observer } from "mobx-react-lite";
import EditButton from './EditButton';
import CTAButton from './CTAButton';
import {contentForDisplayMode} from './Stores';
import sanitizeHtml from 'sanitize-html';
const SanitizeOptions = {
	allowedTags: ['b', 'i', 'u', 'a', 'em', 'strong', 'p', 'br'],
	allowedAttributes: {
		'a': [ 'href' ]
	}
}

const IntroText = observer(({ bioStore, interfaceStateStore, theme, themeProperties, displayMode, serverSynchronization }) => {
	const heading = contentForDisplayMode(displayMode, bioStore.heading, "Welcome!", true);
	const body = contentForDisplayMode(displayMode, bioStore.body, "<p>Use this place to grab your reader’s attention with a short and sweet message.</p>", true);
	const sanitizedBody = body ? sanitizeHtml(body, SanitizeOptions) : null;
	
	const isAdd = (!bioStore.heading && !bioStore.body);
	let editButton = null;
	if(displayMode === "editing") {
		editButton = (
			<EditButton
				addLabel="Add Intro"
				isAdd={isAdd}
				onClick={()  => interfaceStateStore.setCurrentlyEditing("intro-text", null, null)}
			>
			</EditButton>
		);
	}
	
	return (
		<div className="intro-text">
			{editButton}
			<h1 className="intro-text-heading">{heading}</h1>
			<div className="intro-text-body" dangerouslySetInnerHTML={ { __html: sanitizedBody } }></div>
			{ interfaceStateStore.hasPaidPlan && <CTAButton bioStore={bioStore} interfaceStateStore={interfaceStateStore} theme={theme} themeProperties={themeProperties} displayMode={displayMode} serverSynchronization={serverSynchronization} actionType="cta-button"></CTAButton> }
		</div>
	);
});
 
export default IntroText;