import React from 'react';
import { observer } from "mobx-react-lite";
import { Modal, Button } from 'rsuite';

const CloneIntroPopup = observer(({interfaceStateStore, bioStore}) => {
	function done() {
		interfaceStateStore.setIntroState("none");
	}
	function cancel() {
		interfaceStateStore.setIntroState("none");
	}
	
	const qualityCheckStatus = bioStore.getQualityCheckStatus();
	const keys = Object.keys(qualityCheckStatus);
	const complete = keys.length === 0;
	
	const nameParts = (interfaceStateStore.bioAuthorName || "").split(" ");
	let firstNamePart = "";
	if(nameParts.length > 0) firstNamePart = nameParts[0] + " ";
	
	let helpItems = null;
	if(!complete) {
		helpItems = (
			<div className="edit-panel-help">
				<p>Welcome {firstNamePart}</p> 
				<p>The template you just clicked to copy is now at your fingertips in your account, all set for your personal touch! Just click the buttons below to locate any &#123;brackets&#125; and easily edit them following the suggestions you'll find. When you're all set, you can preview your link, switch themes if you like, and then click "Done" when you're ready to publish and share with everyone!</p>
				<hr></hr>
			</div>
		);
	} else {
		helpItems = (
			<div className="edit-panel-help">
				<p>Fantastic work {firstNamePart}, your link is all set! Click "continue" to view, and when you're ready to share, just click "done."</p> 
			</div>
		);
	}
	
	return (
		<Modal show={true} className="edit-panel quality-check-panel" overflow={false}>
			<Modal.Body className="edit-panel-body">
				{ helpItems }
				{
					keys.map(key => {
						const items = qualityCheckStatus[key];
						return items.map(item => {
							return (
								<button
									className="edit-button"
									onClick={() => interfaceStateStore.setCurrentlyEditing(key, item.itemID || null, null)}
								>
									<i className="fas fa-pen-square"></i>
									<span>{item.label}</span>
								</button>
							);
						});
					})
				}
			</Modal.Body>
			<Modal.Footer className="edit-panel-meta-controls">
				{ complete && <Button onClick={done} appearance="primary">Continue</Button> }
				{ !complete && <Button onClick={cancel} appearance="primary" className="cancel-button">Click to customize on your own</Button> }
			</Modal.Footer>
		</Modal>
	)
});
 
export default CloneIntroPopup;
