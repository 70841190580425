import React from 'react';
import { observer } from "mobx-react-lite";
import EditPanel from './EditPanel';
import { Input } from 'rsuite';

const ContactSocialMediaListEditPanel = observer(({ bioStore, interfaceStateStore }) => {
	function facebookURLChange(value) {
		bioStore.setFacebookURL(value);
	}
	function pinterestURLChange(value) {
		bioStore.setPinterestURL(value);
	}
	function twitterHandleChange(value) {
		bioStore.setTwitterHandle(value);
	}
	function instagramUsernameChange(value) {
		bioStore.setInstagramUsername(value);
	}
	function youtubeURLChange(value) {
		bioStore.setYoutubeURL(value);
	}
	function linkedinURLChange(value) {
		bioStore.setLinkedinURL(value);
	}
	function tikTokUsernameChange(value) {
		bioStore.setTikTokUsername(value);
	}
	function amazonURLChange(value) {
		bioStore.setAmazonURL(value);
	}
	function deleteContent() {
		bioStore.setFacebookURL("");
		bioStore.setPinterestURL("");
		bioStore.setTwitterHandle("");
		bioStore.setInstagramUsername("");
		bioStore.setYoutubeURL("");
		bioStore.setLinkedinURL("");
		bioStore.setTikTokUsername("");
		bioStore.setAmazonURL("");
		interfaceStateStore.setCurrentlyEditing(null, null, null);
	}
		
	return (
		<EditPanel
			bioStore={bioStore}
			interfaceStateStore={interfaceStateStore}
			itemType="contact-social-media-list"
			title="Social Accounts"
			helpText={{
				'bio': 'Add links to social media accounts you want to share. This allows your visitors to connect with you on other platforms. Blank fields will not be shown.',
				'quickclick': 'Add links to social media accounts you want to share. This allows your guests to connect with you on other platforms. Blank fields will not be shown.',
				'quiz': 'Add links to social media accounts you want to share. This allows your visitors to connect with you on other platforms. Blank fields will not be shown.',
				'informational': ' Add links to social media accounts you want to share. This allows your visitors to connect with you on other platforms. Blank fields will not be shown.'
			}}
			proTipText={{
				'bio': 'Follow the prompts displayed in each box below to link to your social accounts.',
				'quickclick': 'Follow the prompts displayed in each box below to link to your social accounts.',
				'quiz': 'Follow the prompts displayed in each box below to link to your social accounts.',
				'informational': 'Follow the prompts displayed in each box below to link to your social accounts.'
			}}
			showDelete={true}
			onDelete={deleteContent}
			deleteLabel="Clear this section"
			showTipsButton={true}
		>
			<div className="edit-panel-field">
				<label>Facebook URL:</label>
				<Input value={bioStore.facebookURL || ""} onChange={facebookURLChange} placeholder="https://facebook.com/facebookpageurl" />
			</div>
			<div className="edit-panel-field">
				<label>Pinterest URL:</label>
				<Input value={bioStore.pinterestURL || ""} onChange={pinterestURLChange} placeholder="https://www.pinterest.com/yourpinterestusername/" />
			</div>
			<div className="edit-panel-field">
				<label>Twitter Handle:</label>
				<Input value={bioStore.twitterHandle || ""} onChange={twitterHandleChange} placeholder="@yourtwitterhandle" />
			</div>
			<div className="edit-panel-field">
				<label>Instagram:</label>
				<Input value={bioStore.instagramUsername || ""} onChange={instagramUsernameChange} placeholder="@yourinstagramusername" />
			</div>
			<div className="edit-panel-field">
				<label>YouTube URL:</label>
				<Input value={bioStore.youtubeURL || ""} onChange={youtubeURLChange} placeholder="https://youtube.com/channel/youtubechannelurl" />
			</div>
			<div className="edit-panel-field">
				<label>LinkedIn URL:</label>
				<Input value={bioStore.linkedinURL || ""} onChange={linkedinURLChange} placeholder="https://linkedin.com/in/username" />
			</div>
			<div className="edit-panel-field">
				<label>TikTok Username:</label>
				<Input value={bioStore.tikTokUsername || ""} onChange={tikTokUsernameChange} placeholder="@tiktokusername" />
			</div>
			<div className="edit-panel-field">
				<label>Amazon URL:</label>
				<Input value={bioStore.amazonURL || ""} onChange={amazonURLChange} placeholder="https://amazon.com/shop/yourshopname" />
			</div>
		</EditPanel>
	);
});
 
export default ContactSocialMediaListEditPanel;
