import React from 'react';
import ContactDetails from './ContactDetails';
import ContactSocialMediaList from './ContactSocialMediaList';
import Photo from './Photo';

const ContactRow = ({bioStore, interfaceStateStore, theme, themeProperties, displayMode, serverSynchronization}) => {
	let accentPhoto = null;
	if(themeProperties.contactAccentPhoto) {
		accentPhoto = (
		<div className="contact-accent-photo-container">
			<Photo bioStore={bioStore} interfaceStateStore={interfaceStateStore} displayMode={displayMode} serverSynchronization={serverSynchronization} slot="contact-accent-photo" urlForEditing={themeProperties.contactAccentPhoto} urlForPreviewing={themeProperties.contactAccentPhoto} imageClasses="contact-accent-photo" classes="contact-accent-photo-wrapper"></Photo>
		</div>
		);
	}
	const socialMedia = <ContactSocialMediaList bioStore={bioStore} interfaceStateStore={interfaceStateStore} theme={theme} themeProperties={themeProperties} displayMode={displayMode} serverSynchronization={serverSynchronization}></ContactSocialMediaList>;
	return (
		<div className="contact-row">
			<div className="contact-column">
				<ContactDetails bioStore={bioStore} interfaceStateStore={interfaceStateStore} theme={theme} themeProperties={themeProperties} displayMode={displayMode} serverSynchronization={serverSynchronization}></ContactDetails>
				{ socialMedia }
			</div>
			<div className="contact-accent-column">
				{accentPhoto}
			</div>
		</div>
	);
};
 
export default ContactRow;