import React from 'react';
import { observer } from "mobx-react-lite";
import { Modal, Button } from 'rsuite';

const QualityCheck = observer(({
	bioStore,
	interfaceStateStore
}) => {
	function done() {
		interfaceStateStore.setDoneScreenPhase("done");
	}
	function cancel() {
		interfaceStateStore.setCurrentlyEditing(null, null, null);
		interfaceStateStore.setDoneScreenPhase("none");
	}

	if(interfaceStateStore.doneScreenPhase !== "qualityCheck") return null;
	
	const qualityCheckStatus = bioStore.getQualityCheckStatus();
	const keys = Object.keys(qualityCheckStatus);
	const complete = keys.length === 0;
	
	let helpItems = null;
	if(!complete) {
		const nameParts = (interfaceStateStore.bioAuthorName || "").split(" ");
		let firstNamePart = "";
		if(nameParts.length > 0) firstNamePart = nameParts[0] + " ";
		
		helpItems = (
			<div className="edit-panel-help">
				<p>Oops, {firstNamePart}looks like you still need to update the following before your link is done. </p> 
				<p>Click each button below to find the &#123;Brackets&#125; in each editor and fill in with your information to complete your link.</p>
				<hr></hr>
			</div>
		);
	} else {
		helpItems = (
			<div className="edit-panel-help">
				<p>Great job! Looks like your link is ready! Click continue to publish your link!</p> 
			</div>
		);
	}
	
	return (
		<Modal show={true} className="edit-panel quality-check-panel" overflow={false}>
			<Modal.Body className="edit-panel-body">
				<h1>You're not finished yet...</h1>
				{ helpItems }
				{
					keys.map(key => {
						const items = qualityCheckStatus[key];
						return items.map(item => {
							return (
								<button
									className="edit-button"
									onClick={() => interfaceStateStore.setCurrentlyEditing(key, item.itemID || null, null)}
								>
									<i className="fas fa-pen-square"></i>
									<span>{item.label}</span>
								</button>
							);
						});
					})
				}
			</Modal.Body>
			<Modal.Footer className="edit-panel-meta-controls">
				{ complete && <Button onClick={done} appearance="primary">Continue</Button> }
				{ !complete && <Button onClick={cancel} appearance="primary" className="cancel-button">Cancel</Button> }
			</Modal.Footer>
		</Modal>
	)
});
 
export default QualityCheck;
