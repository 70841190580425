import React from 'react';
import HelpButton from './HelpButton';
import { linkURLTypeString } from './Stores';

const LinkTypeDropdown = ({ url, onChange }) => {
	const typeString = linkURLTypeString(url);
	
	return (
		<div className="edit-panel-field">
			<label>Button Purpose:</label>
			<div className="input-with-side-button">
				<select value={typeString} onChange={(evt) => onChange(evt.target.value)}>
					<option value="url">Link to URL</option>
					<option value="question">Ask a Question</option>
					<option value="upload">Upload a File</option>
				</select>
				<HelpButton>
					<p>From the dropdown above, select if you would like to link to a URL directing your visitors to a specific link, ask your visitors a question to review their responses, or open an image/PDF/video.</p>
				</HelpButton>
			</div>
		</div>
			

	);
};
 
export default LinkTypeDropdown;
