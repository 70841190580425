const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.setAttribute('crossorigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', error => reject(error))
    image.src = url
  })

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
export default async function getCroppedImg(imageSrc, pixelCrop, maxDimension) {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  // Don't go any larger than we need to for the provided image
  const imageMaxDimension = Math.max(image.width, image.height);
  const outputSize = Math.min(imageMaxDimension, maxDimension);
  
  canvas.width = outputSize;
  canvas.height = outputSize;
  
  ctx.drawImage(image, 
    pixelCrop.x, pixelCrop.y,   // Start at 70/20 pixels from the left and the top of the image (crop),
    pixelCrop.width, pixelCrop.height,   // "Get" a `50 * 50` (w * h) area from the source image (crop),
    0, 0,     // Place the result at 0, 0 in the canvas,
    outputSize, outputSize); // With as width / height: 100 * 100 (scale)

  // As a blob
  return new Promise(resolve => {
    canvas.toBlob(file => {
      resolve(file)
    }, 'image/jpeg', 0.8)
  })
}
