import React from 'react';
import { observer } from "mobx-react-lite";
import EditPanel from './EditPanel';
import { Input } from 'rsuite';
import TreatPhoneNumberAsSMSPrompt from './TreatPhoneNumberAsSMSPrompt';
import { isConsideredPhoneNumber } from './URLCleanupUtil';
import { linkURLTypeString } from './Stores';
import HelpButton from './HelpButton';
import LinkTypeDropdown from './LinkTypeDropdown';
import LinkURLQuestionEditFields from './LinkURLQuestionEditFields';
import LinkURLUploadEditFields from './LinkURLUploadEditFields';

const LinkEditPanel = observer(({ bioStore, interfaceStateStore, serverSynchronization }) => {
	const id = interfaceStateStore.currentlyEditingItemID;
	const link = id ? bioStore.getLinkByID(id) : null;
	const label = link ? link.label : "";
	const url = link ? link.url : "";
	const urlTypeString = linkURLTypeString(url);
	
	function linkURLTypeChange(value) {
		if(link) {
			bioStore.changeLinkURLType(id, value);
		}
	}
	function labelChange(value) {
		bioStore.setLinkLabel(id, value);
	}
	function urlChange(value) {
		bioStore.setLinkURL(id, value);
	}
	function deleteLink() {
		if(link) {
			function deleteLinkFromStores() {
				interfaceStateStore.setCurrentlyEditing(null, null, null);
				bioStore.deleteLink(link.id);
			}
			
			if(linkURLTypeString(link.url) === "upload" && link.url.id) {
				serverSynchronization.deleteFile(link.url.id, deleteLinkFromStores);	
			} else {
				// There's no upload to clean up, so delete immediately
				deleteLinkFromStores();	
			}
		}
	}
	function treatPhoneNumberAsSMSChange(value) {
		bioStore.setLinkTreatPhoneNumberAsSMS(id, value);	
	}
	function moveLink(offset) {
		bioStore.moveLink(id, offset);
	}
	function createURLControls(link) {
		return (
			<div className="edit-panel-field">
				<label>Button Link URL:</label>
				<div className="input-with-side-button">
					<Input value={url || ""} onChange={urlChange} />
					<HelpButton>
						<p>Link to anything! As long as it has a URL you can link to it! Websites, Canva links, Venmo, Youtube, Google Docs, Google Share, Google Forms, Email lists, Sign up forms, PDFs, Email address, AND a phone number to text or call.</p>
					</HelpButton>
				</div>
				{ isConsideredPhoneNumber(url) && <TreatPhoneNumberAsSMSPrompt value={link.treatPhoneNumberAsSMS} onChange={treatPhoneNumberAsSMSChange}></TreatPhoneNumberAsSMSPrompt> }
			</div>
		);
	}

	let linkURLSettings = null;
	switch(urlTypeString) {
		case "url":
			linkURLSettings = createURLControls(link);
			break;
		case "question":
			linkURLSettings = <LinkURLQuestionEditFields linkURL={url} hasPaidPlan={interfaceStateStore.hasPaidPlan}></LinkURLQuestionEditFields>;
			break;
		default:
			linkURLSettings = <LinkURLUploadEditFields linkURL={url} hasPaidPlan={interfaceStateStore.hasPaidPlan} serverSynchronization={serverSynchronization}></LinkURLUploadEditFields>;
			break;
	}
	
	return (
		<EditPanel
			bioStore={bioStore}
			interfaceStateStore={interfaceStateStore}
			itemType="link"
			title="Button"
			showDelete={true}
			deleteLabel="Delete Button"
			onDelete={deleteLink}
			helpText={{
				'bio': 'Identify your most important links to direct your visitors to what matters most.',
				'quickclick': 'Identify your most important links to direct your guests to what matters most.',
				'quiz': 'Select the "Ask a Question" as your button purpose. Next, identify which questions you want to ask to engage and interact with your visitors. Their contact info will be collected and sent to you via email, which allows for easy follow-up!',
				'informational': 'Identify your most important links to direct your visitors to what matters most.'
			}}
			proTipText={{
				'bio': "Less is more! Give your button titles a clear call-to-action.",
				'quickclick': 'Less is more! Give your button titles a clear call-to-action.',
				'quiz': 'Give your button titles a clear call-to-action.',
				'informational': 'Less is more! Give your button titles a clear call-to-action.'
			}}
			showTipsButton={true}
		>
			<div className="edit-panel-field">
				<label>Button Title:</label>
				<Input value={label || ""} onChange={labelChange} />
			</div>
			<LinkTypeDropdown url={url} onChange={linkURLTypeChange}></LinkTypeDropdown>
			{ linkURLSettings }
			<div className="edit-panel-field">
				<label>Move Button:</label>
				<div className="move-link-buttons">
					<button onClick={() => moveLink(-1)}>Up one</button>
					<button onClick={() => moveLink(1)}>Down one</button>
				</div>
			</div>
		</EditPanel>
	);
});
 
export default LinkEditPanel;